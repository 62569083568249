import React, { useState, useEffect } from 'react';
import '../styles/Users.css';
import { FiSearch } from 'react-icons/fi'; // Import icons

const Users = () => {
  // **State Variables**
  const [users] = useState([]); // Holds all users data
  const [filteredUsers, setFilteredUsers] = useState([]); // Users filtered by search
  const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
  const usersPerPage = 10; // Number of users per page
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering

  /**
   * useEffect Hook
   *
   * This hook runs when the component mounts.
   * Backend Developer: Replace the code inside this hook with an API call to fetch users data.
   */
  useEffect(() => {
    // TODO: Fetch users data from the backend API and update the 'users' state.
    // Example:
    // fetch('/api/users')
    //   .then(response => response.json())
    //   .then(data => {
    //     setUsers(data);
    //     setFilteredUsers(data);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching users:', error);
    //     // Handle error state if needed
    //   });

    // For now, we'll initialize 'filteredUsers' with an empty array
    setFilteredUsers([]);
  }, []);

  // **Handle Search Input**
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterUsers(term);
  };

  // **Filter Users**
  const filterUsers = (term) => {
    let filtered = users;

    // Filter by search term
    if (term) {
      filtered = filtered.filter(
        (user) =>
          user.name.toLowerCase().includes(term.toLowerCase()) ||
          user.email.toLowerCase().includes(term.toLowerCase()) ||
          user.status.toLowerCase().includes(term.toLowerCase())
      );
    }

    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page when filters change
  };

  // **Pagination Logic**
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  // **Handle Page Change**
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // **Handle Add User**
  const handleAddUser = () => {
    // TODO: Implement actual logic to add a new user
    alert('Add User functionality to be implemented.');
  };

  return (
    <div className="users-content">
      {/* Header Section */}
      <header className="users-header">
        <div className="users-title">
          <h2>User List</h2>
        </div>
        {/* Removed Date Selector as per request */}
      </header>

      {/* Action Bar */}
      <div className="users-action-bar">
        {/* Placeholder for alignment */}
        <div className="users-tabs"></div>

        {/* Search and Add User */}
        <div className="users-actions">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search users"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FiSearch className="search-icon" />
          </div>
          <button className="add-user-button" onClick={handleAddUser}>
            Add User
          </button>
        </div>
      </div>

      {/* Users Table */}
      <div className="users-table-container">
        <div className="users-table-wrapper">
          <table className="users-table">
            <thead>
              <tr>
                <th className="narrow-column">Create Date Time</th>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length > 0 ? (
                currentUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.createDateTime}</td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>
                      <span className={`status ${user.status.toLowerCase()}`}>
                        {user.status}
                      </span>
                    </td>
                    <td>
                      {/* Placeholder for actions */}
                      <button className="action-button">Edit</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="no-users">
                    <div className="no-users-message">
                      <p>No users found.</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          {[...Array(totalPages || 1)].map((_, idx) => (
            <button
              key={idx + 1}
              className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(idx + 1)}
              disabled={filteredUsers.length === 0}
            >
              {idx + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Users;
