// src/components/ChargebackHistory.js

import React, { useState, useEffect } from 'react';
import '../styles/History.css';
import { FiSearch } from 'react-icons/fi';

const ChargebackHistory = () => {
  const [chargebacks, setChargebacks] = useState([]);
  const [filteredChargebacks, setFilteredChargebacks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const merchantId = localStorage.getItem('merchantId');

  const fetchChargebacks = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/chargeback-history/${merchantId}/`);
      const data = await response.json();
      if (data.status === 'Success') {
        setChargebacks(data.chargebacks);
        setFilteredChargebacks(data.chargebacks);
      } else {
        console.error('Failed to fetch chargeback history');
      }
    } catch (error) {
      console.error('An error occurred while fetching chargeback history');
    }
  };

  useEffect(() => {
    fetchChargebacks();
  }, []);

  // Filter chargebacks by search term
  useEffect(() => {
    const filtered = chargebacks.filter(
      (chargeback) =>
        chargeback.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredChargebacks(filtered);
  }, [searchTerm, chargebacks]);

  return (
    <div className="history-content">
      <div className="history-header">
        <h2>Chargeback History</h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search chargebacks"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FiSearch className="search-icon" />
        </div>
      </div>
      <div className="history-table-container">
        <table className="history-table">
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Chargeback Fee</th>
            </tr>
          </thead>
          <tbody>
            {filteredChargebacks.length > 0 ? (
              filteredChargebacks.map((chargeback) => (
                <tr key={chargeback.transaction_id}>
                  <td>{new Date(chargeback.date).toLocaleString()}</td>
                  <td>{chargeback.transaction_id}</td>
                  <td>{chargeback.amount}</td>
                  <td>{chargeback.chargeback_fee || 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="no-records">
                  No chargebacks found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChargebackHistory;
