// src/components/Download.js

import React, { useState, useEffect } from 'react';
import '../styles/Download.css';
import { FiDownload, FiSearch } from 'react-icons/fi'; // Import icons

const Download = () => {
  // **State Variables**
  const [reports] = useState([]); // Holds all download reports data
  const [filteredReports, setFilteredReports] = useState([]); // Reports filtered by search
  const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
  const reportsPerPage = 10; // Number of reports per page
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering

  /**
   * useEffect Hook
   *
   * This hook runs when the component mounts.
   * Backend Developer: Replace the code inside this hook with an API call to fetch download reports data.
   */
  useEffect(() => {
    // TODO: Fetch download reports data from the backend API and update the 'reports' state.
    // Example:
    // fetch('/api/download-reports')
    //   .then(response => response.json())
    //   .then(data => {
    //     setReports(data);
    //     setFilteredReports(data);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching reports:', error);
    //     // Handle error state if needed
    //   });

    // For now, we'll initialize 'filteredReports' with an empty array
    setFilteredReports([]);
  }, []);

  // **Handle Search Input**
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterReports(term);
  };

  // **Filter Reports**
  const filterReports = (term) => {
    let filtered = reports;

    // Filter by search term
    if (term) {
      filtered = filtered.filter(
        (report) =>
          report.reportType.toLowerCase().includes(term.toLowerCase()) ||
          report.status.toLowerCase().includes(term.toLowerCase())
      );
    }

    setFilteredReports(filtered);
    setCurrentPage(1); // Reset to first page when filters change
  };

  // **Pagination Logic**
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = filteredReports.slice(
    indexOfFirstReport,
    indexOfLastReport
  );
  const totalPages = Math.ceil(filteredReports.length / reportsPerPage);

  // **Handle Page Change**
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // **Handle Download Reports**
  const handleDownload = () => {
    // TODO: Implement actual download logic to export reports data
    alert('Download functionality to be implemented.');
  };

  return (
    <div className="download-content">
      {/* Header Section */}
      <header className="download-header">
        <div className="download-title">
          <h2>Download</h2>
        </div>
        <div className="date-selector">
          <label htmlFor="start-date" className="visually-hidden">
            Start Date
          </label>
          <input
            type="date"
            id="start-date"
            aria-label="Start Date"
            // TODO: Implement date selection functionality
          />
          <span> to </span>
          <label htmlFor="end-date" className="visually-hidden">
            End Date
          </label>
          <input
            type="date"
            id="end-date"
            aria-label="End Date"
            // TODO: Implement date selection functionality
          />
          {/* Backend Developer: Implement date filtering functionality based on selected dates */}
        </div>
      </header>

      {/* Action Bar */}
      <div className="download-action-bar">
        {/* Placeholder for alignment */}
        <div className="download-tabs"></div>

        {/* Search and Download */}
        <div className="download-actions">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search reports"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FiSearch className="search-icon" />
          </div>
          <button className="download-button" onClick={handleDownload}>
            <FiDownload className="download-icon" />
            Download
          </button>
        </div>
      </div>

      {/* Reports Table */}
      <div className="download-table-container">
        <div className="download-table-wrapper">
          <table className="download-table">
            <thead>
              <tr>
                <th>Create Time</th>
                <th>Report Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Currency</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentReports.length > 0 ? (
                currentReports.map((report) => (
                  <tr key={report.id}>
                    <td>{report.createTime}</td>
                    <td>{report.reportType}</td>
                    <td>{report.startDate}</td>
                    <td>{report.endDate}</td>
                    <td>{report.currency}</td>
                    <td>
                      <span className={`status ${report.status.toLowerCase()}`}>
                        {report.status}
                      </span>
                    </td>
                    <td>
                      {/* Placeholder for actions */}
                      <button className="action-button">View</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="no-reports">
                    <div className="no-reports-message">
                      <p>No reports found.</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          {[...Array(totalPages || 1)].map((_, idx) => (
            <button
              key={idx + 1}
              className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(idx + 1)}
              disabled={filteredReports.length === 0}
            >
              {idx + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Download;
