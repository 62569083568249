// // src/components/LoginPage.js

// import React, { useState } from "react";
// import styles from "../styles/LoginPage.module.css"; // Importing the CSS module for styling
// import Background from "./Background"; // Importing a Background component for aesthetic purposes
// import { useNavigate } from "react-router-dom"; // Importing useNavigate hook for navigation

// /**
//  * LoginPage Component
//  * 
//  * This component renders a login form that allows users to enter their email and password.
//  * It uses a simulated authentication function to validate credentials and navigates the user
//  * to the dashboard upon successful login. Detailed comments are provided to explain each part
//  * of the component for better understanding and future integration with a backend.
//  */
// const LoginPage = () => {
//   // State variables to manage form inputs and UI states
//   const [email, setEmail] = useState(""); // Stores the user's email input
//   const [password, setPassword] = useState(""); // Stores the user's password input
//   const [showPassword, setShowPassword] = useState(false); // Toggles password visibility
//   const [errorMessage, setErrorMessage] = useState(""); // Stores error messages to display to the user
//   const [isLoading, setIsLoading] = useState(false); // Indicates whether the login process is ongoing

//   const navigate = useNavigate(); // Hook to programmatically navigate between routes

//   /**
//    * handleLogin
//    * 
//    * This asynchronous function handles the form submission for login.
//    * It validates the inputs, calls the fake authentication function, and navigates
//    * the user to the dashboard upon successful login. If authentication fails,
//    * it displays appropriate error messages.
//    * 
//    * @param {Object} e - The event object from the form submission
//    */
//   const handleLogin = async (e) => {
//     e.preventDefault(); // Prevents the default form submission behavior

//     // Basic form validation to ensure both fields are filled
//     if (!email || !password) {
//       setErrorMessage("Please enter both Email and Password.");
//       return;
//     }

//     // Additional validation to ensure the email format is correct
//     if (!validateEmail(email)) {
//       setErrorMessage("Please enter a valid Email address.");
//       return;
//     }

//     // Reset any existing error messages and set the loading state
//     setErrorMessage("");
//     setIsLoading(true);

//     try {
//       // Call the fake authentication function with the entered email and password
//       const response = await fakeLoginRequest(email, password);

//       if (response.success) {
//         // If authentication is successful, store the user's email in localStorage
//         localStorage.setItem("merchantEmail", email);

//         // Navigate the user to the dashboard page
//         navigate("/dashboard");
//       } else {
//         // If authentication fails, display an error message
//         setErrorMessage("Invalid Email or Password.");
//       }
//     } catch (error) {
//       // Handle any unexpected errors during the login process
//       setErrorMessage("An unexpected error occurred. Please try again later.");
//       console.error("Login error:", error); // Log the error for debugging purposes
//     } finally {
//       // Reset the loading state regardless of the authentication outcome
//       setIsLoading(false);
//     }
//   };

//   /**
//    * toggleShowPassword
//    * 
//    * Toggles the visibility of the password input field between plain text and obscured.
//    */
//   const toggleShowPassword = () => {
//     setShowPassword((prev) => !prev);
//   };

//   /**
//    * validateEmail
//    * 
//    * Validates the email format using a simple regular expression.
//    * 
//    * @param {string} email - The email address to validate
//    * @returns {boolean} - Returns true if the email format is valid, false otherwise
//    */
//   const validateEmail = (email) => {
//     // Simple regex for basic email validation
//     const re = /\S+@\S+\.\S+/;
//     return re.test(email);
//   };

//   /**
//    * fakeLoginRequest
//    * 
//    * Simulates an authentication request by checking the entered credentials against
//    * predefined valid credentials. This function mimics a real API call and is used
//    * for frontend testing purposes only. Replace this function with real API calls
//    * when the backend is ready.
//    * 
//    * @param {string} email - The email entered by the user
//    * @param {string} password - The password entered by the user
//    * @returns {Promise<Object>} - Resolves with a success object if credentials are valid,
//    *                              otherwise resolves with a failure object
//    */
//   const fakeLoginRequest = (email, password) => {
//     return new Promise((resolve) => {
//       // Simulate a network delay of 1 second
//       setTimeout(() => {
//         // Check if the entered credentials match the predefined valid credentials
//         if (email === "merchant@payinfy.com" && password === "password123") {
//           resolve({ success: true }); // Authentication successful
//         } else {
//           resolve({ success: false }); // Authentication failed
//         }
//       }, 1000);
//     });
//   };

//   return (
//     <Background> {/* Wrapper component to provide background styling */}
//       <div className={styles.loginWrapper}> {/* Container for the login form */}
//         <div className={styles.loginFormContainer}> {/* Inner container for form elements */}
//           <div className={styles.loginLogo}> {/* Container for the logo image */}
//             <img src="/payinfy_logo.png" alt="Payinfy Logo" /> {/* Logo image */}
//           </div>
//           <form onSubmit={handleLogin} noValidate> {/* Login form */}
//             {/* Email Input Group */}
//             <div className={styles.inputGroup}> {/* Styling for input groups */}
//               <input
//                 type="email" // Input type email for proper keyboard on mobile devices
//                 className={styles.inputField} // CSS module class for styling
//                 placeholder="Email" // Placeholder text
//                 value={email} // Controlled input value
//                 onChange={(e) => setEmail(e.target.value)} // Update state on change
//                 required // HTML5 validation for required field
//                 aria-required="true" // Accessibility attribute
//                 aria-describedby="email-error" // Associates input with error message
//                 autoComplete="email" // Enables browser autocomplete for email
//                 autoFocus // Automatically focuses on this input when the component mounts
//               />
//             </div>

//             {/* Password Input Group */}
//             <div className={`${styles.inputGroup} ${styles.passwordGroup}`}> {/* Multiple CSS classes for styling */}
//               <input
//                 type={showPassword ? "text" : "password"} // Toggles input type based on showPassword state
//                 className={styles.inputField} // CSS module class for styling
//                 placeholder="Password" // Placeholder text
//                 value={password} // Controlled input value
//                 onChange={(e) => setPassword(e.target.value)} // Update state on change
//                 required // HTML5 validation for required field
//                 aria-required="true" // Accessibility attribute
//                 aria-describedby="password-error" // Associates input with error message
//                 autoComplete="current-password" // Enables browser autocomplete for password
//               />
//               {/* Button to toggle password visibility */}
//               <button
//                 type="button" // Button type button to prevent form submission
//                 className={styles.showPasswordButton} // CSS module class for styling
//                 onClick={toggleShowPassword} // Handler to toggle password visibility
//                 aria-label={showPassword ? "Hide Password" : "Show Password"} // Accessibility label
//               >
//                 {showPassword ? "Hide" : "Show"} {/* Button text changes based on state */}
//               </button>
//             </div>

//             {/* Error Message Display */}
//             {errorMessage && ( // Conditionally render the error message if it exists
//               <p id="password-error" className={styles.errorMessage} role="alert">
//                 {errorMessage} {/* Display the error message */}
//               </p>
//             )}

//             {/* Login Button */}
//             <button
//               type="submit" // Button type submit to trigger form submission
//               className={styles.loginButton} // CSS module class for styling
//               disabled={isLoading} // Disable the button while loading to prevent multiple submissions
//               aria-disabled={isLoading} // Accessibility attribute to indicate disabled state
//             >
//               {isLoading ? "Logging in..." : "Login"} {/* Button text changes based on loading state */}
//             </button>

//             {/* Forgot Password Link */}
//             <a href="/forgot-password" className={styles.forgotPassword}>
//               Forgot Password? {/* Link to the forgot password page */}
//             </a>
//           </form>
//         </div>
//       </div>
//     </Background>
//   );
// };

// export default LoginPage; // Export the component for use in other parts of the application



// import React, { useState } from "react";
// import styles from "../styles/LoginPage.module.css"; // Importing the CSS module for styling
// import Background from "./Background"; // Importing a Background component for aesthetic purposes
// import { useNavigate } from "react-router-dom"; // Importing useNavigate hook for navigation

// /**
//  * LoginPage Component
//  * 
//  * This component renders a login form that allows users to enter their email and password.
//  * It uses a real API call to validate credentials and navigates the user
//  * to the dashboard upon successful login. It also stores important information such as
//  * email, merchant name, MID, salt, and secret key in localStorage for use in the profile page.
//  */
// const LoginPage = () => {
//   // State variables to manage form inputs and UI states
//   const [email, setEmail] = useState(""); // Stores the user's email input
//   const [password, setPassword] = useState(""); // Stores the user's password input
//   const [showPassword, setShowPassword] = useState(false); // Toggles password visibility
//   const [errorMessage, setErrorMessage] = useState(""); // Stores error messages to display to the user
//   const [isLoading, setIsLoading] = useState(false); // Indicates whether the login process is ongoing

//   const navigate = useNavigate(); // Hook to programmatically navigate between routes

//   /**
//    * handleLogin
//    * 
//    * This asynchronous function handles the form submission for login.
//    * It validates the inputs, calls the real authentication API, and navigates
//    * the user to the dashboard upon successful login. If authentication fails,
//    * it displays appropriate error messages.
//    * 
//    * @param {Object} e - The event object from the form submission
//    */
//   const handleLogin = async (e) => {
//     e.preventDefault();

//     // Basic form validation to ensure both fields are filled
//     if (!email || !password) {
//       setErrorMessage("Please enter both Email and Password.");
//       return;
//     }

//     // Additional validation to ensure the email format is correct
//     if (!validateEmail(email)) {
//       setErrorMessage("Please enter a valid Email address.");
//       return;
//     }

//     setErrorMessage("");
//     setIsLoading(true);

//     try {
//       // Call the real login API
//       const response = await fetch("https://merchant.payinfy.com/merchants/login/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: email,
//           password: password,
//         }),
//       });

//       const data = await response.json();
//       console.log("Login API response:", data); // Debugging line to log the API response

//       if (response.ok && data.status === "Success") {
//         // Ensure that salt and secret_key are present in the response
//         console.log("Salt:", data.salt); // Debugging line
//         console.log("Secret Key:", data.secret_key); // Debugging line

//         // Store necessary data in localStorage
//         localStorage.setItem("merchantEmail", data.email);
//         localStorage.setItem("merchantId", data.merchant_id);
//         localStorage.setItem("merchantName", data.merchant_name); // Full name of merchant
//         localStorage.setItem("salt", data.salt || ""); // Fallback to an empty string if undefined
//         localStorage.setItem("payinSecretKey", data.secret_key || ""); // Fallback to an empty string if undefined

//         // Navigate the user to the dashboard page
//         navigate("/dashboard");
//       } else {
//         // If the login fails, display an error message
//         setErrorMessage("Invalid Email or Password.");
//       }
//     } catch (error) {
//       setErrorMessage("An unexpected error occurred. Please try again later.");
//       console.error("Login error:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   /**
//    * toggleShowPassword
//    * 
//    * Toggles the visibility of the password input field between plain text and obscured.
//    */
//   const toggleShowPassword = () => {
//     setShowPassword((prev) => !prev);
//   };

//   /**
//    * validateEmail
//    * 
//    * Validates the email format using a simple regular expression.
//    * 
//    * @param {string} email - The email address to validate
//    * @returns {boolean} - Returns true if the email format is valid, false otherwise
//    */
//   const validateEmail = (email) => {
//     // Simple regex for basic email validation
//     const re = /\S+@\S+\.\S+/;
//     return re.test(email);
//   };

//   return (
//     <Background> {/* Wrapper component to provide background styling */}
//       <div className={styles.loginWrapper}> {/* Container for the login form */}
//         <div className={styles.loginFormContainer}> {/* Inner container for form elements */}
//           <div className={styles.loginLogo}> {/* Container for the logo image */}
//             <img src="/payinfy_logo.png" alt="Payinfy Logo" /> {/* Logo image */}
//           </div>
//           <form onSubmit={handleLogin} noValidate> {/* Login form */}
//             {/* Email Input Group */}
//             <div className={styles.inputGroup}> {/* Styling for input groups */}
//               <input
//                 type="email" // Input type email for proper keyboard on mobile devices
//                 className={styles.inputField} // CSS module class for styling
//                 placeholder="Email" // Placeholder text
//                 value={email} // Controlled input value
//                 onChange={(e) => setEmail(e.target.value)} // Update state on change
//                 required // HTML5 validation for required field
//                 aria-required="true" // Accessibility attribute
//                 aria-describedby="email-error" // Associates input with error message
//                 autoComplete="email" // Enables browser autocomplete for email
//                 autoFocus // Automatically focuses on this input when the component mounts
//               />
//             </div>

//             {/* Password Input Group */}
//             <div className={`${styles.inputGroup} ${styles.passwordGroup}`}> {/* Multiple CSS classes for styling */}
//               <input
//                 type={showPassword ? "text" : "password"} // Toggles input type based on showPassword state
//                 className={styles.inputField} // CSS module class for styling
//                 placeholder="Password" // Placeholder text
//                 value={password} // Controlled input value
//                 onChange={(e) => setPassword(e.target.value)} // Update state on change
//                 required // HTML5 validation for required field
//                 aria-required="true" // Accessibility attribute
//                 aria-describedby="password-error" // Associates input with error message
//                 autoComplete="current-password" // Enables browser autocomplete for password
//               />
//               {/* Button to toggle password visibility */}
//               <button
//                 type="button" // Button type button to prevent form submission
//                 className={styles.showPasswordButton} // CSS module class for styling
//                 onClick={toggleShowPassword} // Handler to toggle password visibility
//                 aria-label={showPassword ? "Hide Password" : "Show Password"} // Accessibility label
//               >
//                 {showPassword ? "Hide" : "Show"} {/* Button text changes based on state */}
//               </button>
//             </div>

//             {/* Error Message Display */}
//             {errorMessage && ( // Conditionally render the error message if it exists
//               <p id="password-error" className={styles.errorMessage} role="alert">
//                 {errorMessage} {/* Display the error message */}
//               </p>
//             )}

//             {/* Login Button */}
//             <button
//               type="submit" // Button type submit to trigger form submission
//               className={styles.loginButton} // CSS module class for styling
//               disabled={isLoading} // Disable the button while loading to prevent multiple submissions
//               aria-disabled={isLoading} // Accessibility attribute to indicate disabled state
//             >
//               {isLoading ? "Logging in..." : "Login"} {/* Button text changes based on loading state */}
//             </button>

//             {/* Forgot Password Link */}
//             <a href="/forgot-password" className={styles.forgotPassword}>
//               Forgot Password? {/* Link to the forgot password page */}
//             </a>
//           </form>
//         </div>
//       </div>
//     </Background>
//   );
// };

// export default LoginPage;



// File: src/components/LoginPage.jsx

// import React, { useState } from "react";
// import styles from "../styles/LoginPage.module.css"; // Importing the CSS module for styling
// import Background from "./Background"; // Importing a Background component for aesthetic purposes
// import { useNavigate } from "react-router-dom"; // Importing useNavigate hook for navigation

// /**
//  * LoginPage Component
//  * 
//  * This component renders a login form that allows users to enter their email and password.
//  * It uses a real API call to validate credentials and navigates the user
//  * to the dashboard upon successful login. It also stores important information such as
//  * email, merchant name, MID, salt, and secret key in localStorage for use in the profile page.
//  */
// const LoginPage = () => {
//   // State variables to manage form inputs and UI states
//   const [email, setEmail] = useState(""); // Stores the user's email input
//   const [password, setPassword] = useState(""); // Stores the user's password input
//   const [showPassword, setShowPassword] = useState(false); // Toggles password visibility
//   const [errorMessage, setErrorMessage] = useState(""); // Stores error messages to display to the user
//   const [isLoading, setIsLoading] = useState(false); // Indicates whether the login process is ongoing

//   const navigate = useNavigate(); // Hook to programmatically navigate between routes

//   /**
//    * handleLogin
//    * 
//    * This asynchronous function handles the form submission for login.
//    * It validates the inputs, calls the real authentication API, and navigates
//    * the user to the dashboard upon successful login. If authentication fails,
//    * it displays appropriate error messages.
//    * 
//    * @param {Object} e - The event object from the form submission
//    */
//   const handleLogin = async (e) => {
//     e.preventDefault();

//     // Basic form validation to ensure both fields are filled
//     if (!email || !password) {
//       setErrorMessage("Please enter both Email and Password.");
//       return;
//     }

//     // Additional validation to ensure the email format is correct
//     if (!validateEmail(email)) {
//       setErrorMessage("Please enter a valid Email address.");
//       return;
//     }

//     setErrorMessage("");
//     setIsLoading(true);

//     try {
//       // Call the real login API
//       const response = await fetch("https://merchant.payinfy.com/merchants/login/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: email,
//           password: password,
//         }),
//       });

//       const data = await response.json();
//       console.log("Login API response:", data); // Debugging line to log the API response

//       if (response.ok && data.status === "Success") {
//         // Ensure that settlement_period, salt, and secret_key are present in the response
//         console.log("Settlement Period:", data.settlement_period); // Debugging line
//         console.log("Salt:", data.salt); // Debugging line
//         console.log("Secret Key:", data.secret_key); // Debugging line

//         // Store necessary data in localStorage
//         localStorage.setItem("merchantEmail", data.email);
//         localStorage.setItem("merchantId", data.merchant_id);
//         localStorage.setItem("merchantName", data.merchant_name); // Full name of merchant
//         localStorage.setItem("salt", data.salt || ""); // Fallback to an empty string if undefined
//         localStorage.setItem("payinSecretKey", data.secret_key || ""); // Fallback to an empty string if undefined
//         localStorage.setItem("settlement_period", data.settlement_period || ""); // Store settlement period

//         // Navigate the user to the dashboard page
//         navigate("/dashboard");
//       } else {
//         // If the login fails, display an error message
//         setErrorMessage("Invalid Email or Password.");
//       }
//     } catch (error) {
//       setErrorMessage("An unexpected error occurred. Please try again later.");
//       console.error("Login error:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   /**
//    * toggleShowPassword
//    * 
//    * Toggles the visibility of the password input field between plain text and obscured.
//    */
//   const toggleShowPassword = () => {
//     setShowPassword((prev) => !prev);
//   };

//   /**
//    * validateEmail
//    * 
//    * Validates the email format using a simple regular expression.
//    * 
//    * @param {string} email - The email address to validate
//    * @returns {boolean} - Returns true if the email format is valid, false otherwise
//    */
//   const validateEmail = (email) => {
//     // Simple regex for basic email validation
//     const re = /\S+@\S+\.\S+/;
//     return re.test(email);
//   };

//   return (
//     <Background> {/* Wrapper component to provide background styling */}
//       <div className={styles.loginWrapper}> {/* Container for the login form */}
//         <div className={styles.loginFormContainer}> {/* Inner container for form elements */}
//           <div className={styles.loginLogo}> {/* Container for the logo image */}
//             <img src="/payinfy_logo.png" alt="Payinfy Logo" /> {/* Logo image */}
//           </div>
//           <form onSubmit={handleLogin} noValidate> {/* Login form */}
//             {/* Email Input Group */}
//             <div className={styles.inputGroup}> {/* Styling for input groups */}
//               <input
//                 type="email" // Input type email for proper keyboard on mobile devices
//                 className={styles.inputField} // CSS module class for styling
//                 placeholder="Email" // Placeholder text
//                 value={email} // Controlled input value
//                 onChange={(e) => setEmail(e.target.value)} // Update state on change
//                 required // HTML5 validation for required field
//                 aria-required="true" // Accessibility attribute
//                 aria-describedby="email-error" // Associates input with error message
//                 autoComplete="email" // Enables browser autocomplete for email
//                 autoFocus // Automatically focuses on this input when the component mounts
//               />
//             </div>

//             {/* Password Input Group */}
//             <div className={`${styles.inputGroup} ${styles.passwordGroup}`}> {/* Multiple CSS classes for styling */}
//               <input
//                 type={showPassword ? "text" : "password"} // Toggles input type based on showPassword state
//                 className={styles.inputField} // CSS module class for styling
//                 placeholder="Password" // Placeholder text
//                 value={password} // Controlled input value
//                 onChange={(e) => setPassword(e.target.value)} // Update state on change
//                 required // HTML5 validation for required field
//                 aria-required="true" // Accessibility attribute
//                 aria-describedby="password-error" // Associates input with error message
//                 autoComplete="current-password" // Enables browser autocomplete for password
//               />
//               {/* Button to toggle password visibility */}
//               <button
//                 type="button" // Button type button to prevent form submission
//                 className={styles.showPasswordButton} // CSS module class for styling
//                 onClick={toggleShowPassword} // Handler to toggle password visibility
//                 aria-label={showPassword ? "Hide Password" : "Show Password"} // Accessibility label
//               >
//                 {showPassword ? "Hide" : "Show"} {/* Button text changes based on state */}
//               </button>
//             </div>

//             {/* Error Message Display */}
//             {errorMessage && ( // Conditionally render the error message if it exists
//               <p id="password-error" className={styles.errorMessage} role="alert">
//                 {errorMessage} {/* Display the error message */}
//               </p>
//             )}

//             {/* Login Button */}
//             <button
//               type="submit" // Button type submit to trigger form submission
//               className={styles.loginButton} // CSS module class for styling
//               disabled={isLoading} // Disable the button while loading to prevent multiple submissions
//               aria-disabled={isLoading} // Accessibility attribute to indicate disabled state
//             >
//               {isLoading ? "Logging in..." : "Login"} {/* Button text changes based on loading state */}
//             </button>

//             {/* Forgot Password Link */}
//             <a href="/forgot-password" className={styles.forgotPassword}>
//               Forgot Password? {/* Link to the forgot password page */}
//             </a>
//           </form>
//         </div>
//       </div>
//     </Background>
//   );
// };

// export default LoginPage;



// import React, { useState } from "react";
// import styles from "../styles/LoginPage.module.css";
// import Background from "./Background";
// import { useNavigate } from "react-router-dom";

// const LoginPage = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   const navigate = useNavigate();

//   const handleLogin = async (e) => {
//     e.preventDefault();

//     if (!email || !password) {
//       setErrorMessage("Please enter both Email and Password.");
//       return;
//     }

//     if (!validateEmail(email)) {
//       setErrorMessage("Please enter a valid Email address.");
//       return;
//     }

//     setErrorMessage("");
//     setIsLoading(true);

//     try {
//       const response = await fetch("https://merchant.payinfy.com/merchants/login/", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: email,
//           password: password,
//         }),
//       });

//       const data = await response.json();
//       console.log("Login API response:", data);

//       if (response.ok && data.status === "Success") {
//         localStorage.setItem("merchantEmail", data.email);
//         localStorage.setItem("merchantId", data.merchant_id);
//         localStorage.setItem("merchantName", data.merchant_name);
//         localStorage.setItem("salt", data.salt || "");
//         localStorage.setItem("payinSecretKey", data.secret_key || "");
//         localStorage.setItem("settlement_period", data.settlement_period || "");

//         // Store refundFee and chargebackFee
//         localStorage.setItem("refundFee", data.refund_fee || "");
//         localStorage.setItem("chargebackFee", data.chargeback_fee || "");

//         navigate("/dashboard");
//       } else {
//         setErrorMessage("Invalid Email or Password.");
//       }
//     } catch (error) {
//       setErrorMessage("An unexpected error occurred. Please try again later.");
//       console.error("Login error:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const toggleShowPassword = () => {
//     setShowPassword((prev) => !prev);
//   };

//   const validateEmail = (email) => {
//     const re = /\S+@\S+\.\S+/;
//     return re.test(email);
//   };

//   return (
//     <Background>
//       {/* ... existing code ... */}
//     </Background>
//   );
// };

// export default LoginPage;



import React, { useState } from "react";
import styles from "../styles/LoginPage.module.css"; // Importing the CSS module for styling
import Background from "./Background"; // Importing a Background component for aesthetic purposes
import { useNavigate } from "react-router-dom"; // Importing useNavigate hook for navigation

/**
 * LoginPage Component
 * 
 * This component renders a login form that allows users to enter their email and password.
 * It uses a real API call to validate credentials and navigates the user
 * to the dashboard upon successful login. It also stores important information such as
 * email, merchant name, MID, salt, and secret key in localStorage for use in the profile page.
 */
const LoginPage = () => {
  // State variables to manage form inputs and UI states
  const [email, setEmail] = useState(""); // Stores the user's email input
  const [password, setPassword] = useState(""); // Stores the user's password input
  const [showPassword, setShowPassword] = useState(false); // Toggles password visibility
  const [errorMessage, setErrorMessage] = useState(""); // Stores error messages to display to the user
  const [isLoading, setIsLoading] = useState(false); // Indicates whether the login process is ongoing

  const navigate = useNavigate(); // Hook to programmatically navigate between routes

  /**
   * handleLogin
   * 
   * This asynchronous function handles the form submission for login.
   * It validates the inputs, calls the real authentication API, and navigates
   * the user to the dashboard upon successful login. If authentication fails,
   * it displays appropriate error messages.
   * 
   * @param {Object} e - The event object from the form submission
   */
  const handleLogin = async (e) => {
    e.preventDefault();

    // Basic form validation to ensure both fields are filled
    if (!email || !password) {
      setErrorMessage("Please enter both Email and Password.");
      return;
    }

    // Additional validation to ensure the email format is correct
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid Email address.");
      return;
    }

    setErrorMessage("");
    setIsLoading(true);

    try {
      // Call the real login API
      const response = await fetch("https://merchant.payinfy.com/merchants/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const data = await response.json();
      console.log("Login API response:", data); // Debugging line to log the API response

      if (response.ok && data.status === "Success") {
        // Ensure that settlement_period, salt, and secret_key are present in the response
        console.log("Settlement Period:", data.settlement_period); // Debugging line
        console.log("Salt:", data.salt); // Debugging line
        console.log("Secret Key:", data.secret_key); // Debugging line
        console.log("Refund Fee:", data.refund_fee); // Debugging line
        console.log("Chargeback Fee:", data.chargeback_fee); // Debugging line

        // Store necessary data in localStorage
        localStorage.setItem("merchantEmail", data.email);
        localStorage.setItem("merchantId", data.merchant_id);
        localStorage.setItem("merchantName", data.merchant_name); // Full name of merchant
        localStorage.setItem("salt", data.salt || ""); // Fallback to an empty string if undefined
        localStorage.setItem("payinSecretKey", data.secret_key || ""); // Fallback to an empty string if undefined
        localStorage.setItem("settlement_period", data.settlement_period || ""); // Store settlement period

        // Store refundFee and chargebackFee
        localStorage.setItem("refundFee", data.refund_fee || ""); // Added line
        localStorage.setItem("chargebackFee", data.chargeback_fee || ""); // Added line

        // Navigate the user to the dashboard page
        navigate("/dashboard");
      } else {
        // If the login fails, display an error message
        setErrorMessage("Invalid Email or Password.");
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again later.");
      console.error("Login error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * toggleShowPassword
   * 
   * Toggles the visibility of the password input field between plain text and obscured.
   */
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  /**
   * validateEmail
   * 
   * Validates the email format using a simple regular expression.
   * 
   * @param {string} email - The email address to validate
   * @returns {boolean} - Returns true if the email format is valid, false otherwise
   */
  const validateEmail = (email) => {
    // Simple regex for basic email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <Background> {/* Wrapper component to provide background styling */}
      <div className={styles.loginWrapper}> {/* Container for the login form */}
        <div className={styles.loginFormContainer}> {/* Inner container for form elements */}
          <div className={styles.loginLogo}> {/* Container for the logo image */}
            <img src="/payinfy_logo.png" alt="Payinfy Logo" /> {/* Logo image */}
          </div>
          <form onSubmit={handleLogin} noValidate> {/* Login form */}
            {/* Email Input Group */}
            <div className={styles.inputGroup}> {/* Styling for input groups */}
              <input
                type="email" // Input type email for proper keyboard on mobile devices
                className={styles.inputField} // CSS module class for styling
                placeholder="Email" // Placeholder text
                value={email} // Controlled input value
                onChange={(e) => setEmail(e.target.value)} // Update state on change
                required // HTML5 validation for required field
                aria-required="true" // Accessibility attribute
                aria-describedby="email-error" // Associates input with error message
                autoComplete="email" // Enables browser autocomplete for email
                autoFocus // Automatically focuses on this input when the component mounts
              />
            </div>

            {/* Password Input Group */}
            <div className={`${styles.inputGroup} ${styles.passwordGroup}`}> {/* Multiple CSS classes for styling */}
              <input
                type={showPassword ? "text" : "password"} // Toggles input type based on showPassword state
                className={styles.inputField} // CSS module class for styling
                placeholder="Password" // Placeholder text
                value={password} // Controlled input value
                onChange={(e) => setPassword(e.target.value)} // Update state on change
                required // HTML5 validation for required field
                aria-required="true" // Accessibility attribute
                aria-describedby="password-error" // Associates input with error message
                autoComplete="current-password" // Enables browser autocomplete for password
              />
              {/* Button to toggle password visibility */}
              <button
                type="button" // Button type button to prevent form submission
                className={styles.showPasswordButton} // CSS module class for styling
                onClick={toggleShowPassword} // Handler to toggle password visibility
                aria-label={showPassword ? "Hide Password" : "Show Password"} // Accessibility label
              >
                {showPassword ? "Hide" : "Show"} {/* Button text changes based on state */}
              </button>
            </div>

            {/* Error Message Display */}
            {errorMessage && ( // Conditionally render the error message if it exists
              <p id="password-error" className={styles.errorMessage} role="alert">
                {errorMessage} {/* Display the error message */}
              </p>
            )}

            {/* Login Button */}
            <button
              type="submit" // Button type submit to trigger form submission
              className={styles.loginButton} // CSS module class for styling
              disabled={isLoading} // Disable the button while loading to prevent multiple submissions
              aria-disabled={isLoading} // Accessibility attribute to indicate disabled state
            >
              {isLoading ? "Logging in..." : "Login"} {/* Button text changes based on loading state */}
            </button>

            {/* Forgot Password Link */}
            <a href="/forgot-password" className={styles.forgotPassword}>
              Forgot Password? {/* Link to the forgot password page */}
            </a>
          </form>
        </div>
      </div>
    </Background>
  );
};

export default LoginPage;

