// ForgotPassword.js

import React from "react";
import styles from "../styles/ForgotPassword.module.css"; // Importing the CSS module
import Background from "./Background"; // Reusing the same background for consistency

const ForgotPassword = () => {
  return (
    <Background>
      <div className={styles.forgotPasswordWrapper}>
        <div className={styles.forgotPasswordContainer}>
          <h2 className={styles.heading}>Forgot Password</h2>
          <p className={styles.paragraph}>
            For security reasons, please contact the admin to reset your password.
          </p>
          <p className={styles.paragraph}>
            You can contact us at:{" "}
            <a href="mailto:admin@payinfy.com" className={styles.link}>
              admin@payinfy.com
            </a>
          </p>
          <a href="/" className={styles.backToLoginButton}>
            Back to Login
          </a>
        </div>
      </div>
    </Background>
  );
};

export default ForgotPassword;
