// Background.js

import React from 'react';
import styles from '../styles/Background.module.css'; // Ensure the correct path to your CSS module

const Background = ({ children }) => {
  return (
    <div className={styles.backgroundWrapper}>
      {/* Shape Elements */}
      <div className={`${styles.shape} ${styles.shapeBefore}`}></div>
      <div className={`${styles.shape} ${styles.shapeAfter}`}></div>
      <div className={`${styles.shape} ${styles.shapeThird}`}></div>
      <div className={`${styles.shape} ${styles.shapeFourth}`}></div>
      <div className={`${styles.shape} ${styles.shapeFifth}`}></div>
      <div className={`${styles.shape} ${styles.shapeSixth}`}></div>

      {/* Main Content */}
      {children}
    </div>
  );
};

export default Background;
