// import React, { useEffect, useState } from 'react';
// import '../styles/Dashboard.css'; // Importing the CSS file for Dashboard
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts'; // Importing Recharts components for data visualization

// /**
//  * Dashboard Component
//  *
//  * Displays merchant statistics and a transaction chart.
//  */
// const Dashboard = () => {
//   // **State Variables**
//   const [merchantName, setMerchantName] = useState('');
//   const [stats, setStats] = useState({
//     success: { count: 0, amount: 0 },
//     pending: { count: 0, amount: 0 },
//     failed: { count: 0, amount: 0 },
//   });
//   const [chartData, setChartData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');

//   /**
//    * useEffect Hook
//    *
//    * Retrieves the merchant's name from localStorage.
//    */
//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     if (storedMerchantName) {
//       setMerchantName(storedMerchantName);
//     } else {
//       setMerchantName('Merchant'); // Default name if not available
//     }
//   }, []);

//   /**
//    * useEffect Hook
//    *
//    * Fetches statistics and chart data. Currently uses mock data for demonstration.
//    */
//   useEffect(() => {
//     const fetchStats = async () => {
//       try {
//         // Mock data for demonstration purposes
//         const mockStatsData = {
//           success: { count: 120, amount: 150000 },
//           pending: { count: 30, amount: 50000 },
//           failed: { count: 10, amount: 20000 },
//         };
//         const mockChartData = generateMockChartData();

//         // Simulate an API delay of 1 second
//         await new Promise((resolve) => setTimeout(resolve, 1000));

//         // Update state with mock data
//         setStats(mockStatsData);
//         setChartData(mockChartData);
//       } catch (err) {
//         console.error('Error fetching data:', err);
//         setError('Failed to load dashboard data. Please try again later.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchStats();
//   }, []);

//   /**
//    * generateMockChartData
//    *
//    * Generates mock data for the transaction chart with aligned 10-minute intervals.
//    */
//   const generateMockChartData = () => {
//     const data = [];
//     const now = new Date();

//     // Align current time to the nearest past 10-minute interval
//     const alignedNow = new Date(now);
//     alignedNow.setMinutes(Math.floor(alignedNow.getMinutes() / 10) * 10);
//     alignedNow.setSeconds(0);
//     alignedNow.setMilliseconds(0);

//     // Generate data points for the past hour at 10-minute intervals
//     for (let i = 5; i >= 0; i--) {
//       const time = new Date(alignedNow.getTime() - i * 10 * 60000); // Subtract i*10 minutes
//       const hours = time.getHours().toString().padStart(2, '0');
//       const minutes = time.getMinutes().toString().padStart(2, '0');
//       const label = `${hours}:${minutes}`; // Format as HH:MM
//       data.push({
//         time: label,
//         Success: Math.floor(Math.random() * 20) + 10, // Random number between 10-30
//         Pending: Math.floor(Math.random() * 10) + 5,  // Random number between 5-15
//         Failed: Math.floor(Math.random() * 5) + 1,    // Random number between 1-5
//       });
//     }

//     return data;
//   };

//   // **Conditional Rendering based on 'loading' and 'error' states**
//   if (loading) {
//     return (
//       <div className="loading-spinner" role="status" aria-live="polite">
//         <div className="spinner"></div>
//         <span>Loading...</span>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="error-message" role="alert">
//         <p>{error}</p>
//       </div>
//     );
//   }

//   // **Main Dashboard Content**
//   return (
//     <div className="dashboard-content">
//       {/* Header Section */}
//       <header className="dashboard-header">
//         <div className="welcome-message">
//           <h2>Welcome, {merchantName}</h2>
//         </div>
//         <div className="date-selector">
//           <label htmlFor="start-date" className="visually-hidden">
//             Start Date
//           </label>
//           <input
//             type="date"
//             id="start-date"
//             aria-label="Start Date"
//           />
//           <span> to </span>
//           <label htmlFor="end-date" className="visually-hidden">
//             End Date
//           </label>
//           <input
//             type="date"
//             id="end-date"
//             aria-label="End Date"
//           />
//           {/* TODO: Implement date filtering functionality */}
//         </div>
//       </header>

//       {/* Statistics Section */}
//       <section className="dashboard-stats">
//         {/* Success Stat Card */}
//         <div className="stat-card success" tabIndex="0" aria-labelledby="success-stat">
//           <h3 id="success-stat">Success</h3>
//           <p>
//             {stats.success.count} Transactions
//             <br />
//             ₹{stats.success.amount.toLocaleString()}
//           </p>
//         </div>

//         {/* Pending Stat Card */}
//         <div className="stat-card pending" tabIndex="0" aria-labelledby="pending-stat">
//           <h3 id="pending-stat">Pending</h3>
//           <p>
//             {stats.pending.count} Transactions
//             <br />
//             ₹{stats.pending.amount.toLocaleString()}
//           </p>
//         </div>

//         {/* Failed Stat Card */}
//         <div className="stat-card failed" tabIndex="0" aria-labelledby="failed-stat">
//           <h3 id="failed-stat">Failed</h3>
//           <p>
//             {stats.failed.count} Transactions
//             <br />
//             ₹{stats.failed.amount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <ResponsiveContainer width="100%" height="100%">
//             <LineChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="time" tick={{ fontSize: 14 }} interval={0} />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               {/* Define the lines for each transaction status */}
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </ResponsiveContainer>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;



// src/components/Dashboard.js

// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts'; // Importing Recharts components for data visualization

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]); // Holds all transaction data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions for chart
//   const [chartData, setChartData] = useState([]); // Data for the chart
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(''); // Error message state
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [availableBalance, setAvailableBalance] = useState(0); // Available balance for the merchant
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 }); // Success transactions count and total amount
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 }); // Pending transactions count and total amount
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 }); // Failed transactions count and total amount

//   const merchantId = localStorage.getItem('merchantId'); // Merchant ID fetched from local storage

//   // Fetch all transactions from the backend
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions); // Update state with the fetched transactions
//         setFilteredTransactions(data.transactions); // Initialize filtered transactions
//         setChartData(transformDataForChart(data.transactions)); // Transform and set chart data
//         calculateTransactionStats(data.transactions); // Calculate Success, Pending, Failed transactions
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch the available balance from the settlements API
//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount), // Properly sum amounts as floats
//           0
//         );
//         setAvailableBalance(totalBalance); // Set the available balance from the settlements
//       } else {
//         setError('Failed to load available balance.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     // Fetch transaction and balance data when the component mounts
//     fetchTransactions();
//     fetchAvailableBalance();
//   }, []);

//   /**
//    * Filters the transactions based on the selected date range
//    */
//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions); // If no date is selected, reset to all transactions
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered); // Update the filtered transactions state
//       setChartData(transformDataForChart(filtered)); // Update the chart data based on filtered transactions
//       calculateTransactionStats(filtered); // Recalculate stats based on filtered data
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate(); // Apply filtering when the date range changes
//   }, [startDate, endDate, transactions]);

//   /**
//    * Transforms the fetched transaction data into a format suitable for the chart.
//    */
//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   /**
//    * Calculate the number of success, pending, and failed transactions along with their total amounts
//    */
//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   // Conditional rendering based on loading or error states
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="header-left">
//           <div className="welcome-message">
//             <h2>Welcome, {merchantName}</h2>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="available-balance" style={{ marginLeft: '20px' }}>
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//         </div>
//         <div className="date-selector">
//           <input
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             aria-label="Start Date"
//           />
//           <span> to </span>
//           <input
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             aria-label="End Date"
//           />
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <ResponsiveContainer width="100%" height={400}>
//             <LineChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="date" tick={{ fontSize: 14 }} interval={0} />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </ResponsiveContainer>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;



// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]); // Holds all transaction data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions for chart
//   const [chartData, setChartData] = useState([]); // Data for the chart
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(''); // Error message state
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [availableBalance, setAvailableBalance] = useState(0); // Available balance for the merchant
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 }); // Success transactions count and total amount
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 }); // Pending transactions count and total amount
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 }); // Failed transactions count and total amount

//   const merchantId = localStorage.getItem('merchantId'); // Merchant ID fetched from local storage

//   // Fetch all transactions from the backend
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions); // Update state with the fetched transactions
//         setFilteredTransactions(data.transactions); // Initialize filtered transactions
//         setChartData(transformDataForChart(data.transactions)); // Transform and set chart data
//         calculateTransactionStats(data.transactions); // Calculate Success, Pending, Failed transactions
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch the available balance from the settlements API
//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         const totalBalance = data.settlements.length > 0
//           ? data.settlements.reduce(
//               (acc, settlement) => acc + parseFloat(settlement.settlementAmount), // Properly sum amounts as floats
//               0
//             )
//           : 0; // If settlements array is empty, set balance to 0
//         setAvailableBalance(totalBalance); // Set the available balance from the settlements
//       } else {
//         setError('Failed to load available balance.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     // Fetch transaction and balance data when the component mounts
//     fetchTransactions();
//     fetchAvailableBalance();
//   }, []);

//   /**
//    * Filters the transactions based on the selected date range
//    */
//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions); // If no date is selected, reset to all transactions
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered); // Update the filtered transactions state
//       setChartData(transformDataForChart(filtered)); // Update the chart data based on filtered transactions
//       calculateTransactionStats(filtered); // Recalculate stats based on filtered data
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate(); // Apply filtering when the date range changes
//   }, [startDate, endDate, transactions]);

//   /**
//    * Transforms the fetched transaction data into a format suitable for the chart.
//    */
//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   /**
//    * Calculate the number of success, pending, and failed transactions along with their total amounts
//    */
//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   // Conditional rendering based on loading or error states
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="header-left">
//           <div className="welcome-message">
//             <h2>Welcome, {merchantName}</h2>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="available-balance" style={{ marginLeft: '20px' }}>
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//         </div>
//         <div className="date-selector">
//           <input
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             aria-label="Start Date"
//           />
//           <span> to </span>
//           <input
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             aria-label="End Date"
//           />
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <ResponsiveContainer width="100%" height={400}>
//             <LineChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="date" tick={{ fontSize: 14 }} interval={0} />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </ResponsiveContainer>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;



// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]); // Holds all transaction data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions for chart
//   const [chartData, setChartData] = useState([]); // Data for the chart
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(''); // Error message state
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [availableBalance, setAvailableBalance] = useState(0); // Available balance for the merchant
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 }); // Success transactions count and total amount
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 }); // Pending transactions count and total amount
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 }); // Failed transactions count and total amount

//   const merchantId = localStorage.getItem('merchantId'); // Merchant ID fetched from local storage

//   // Fetch all transactions from the backend
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions); // Update state with the fetched transactions
//         setFilteredTransactions(data.transactions); // Initialize filtered transactions
//         setChartData(transformDataForChart(data.transactions)); // Transform and set chart data
//         calculateTransactionStats(data.transactions); // Calculate Success, Pending, Failed transactions
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch the available balance from the settlements API
//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.settlements.length > 0) {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount), // Properly sum amounts as floats
//           0
//         );
//         setAvailableBalance(totalBalance); // Set the available balance from the settlements
//       } else {
//         setAvailableBalance(0); // Set balance to zero if no settlements
//       }
//     } catch (error) {
//       setAvailableBalance(0); // Set balance to zero if there's an error fetching settlements
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     // Fetch transaction and balance data when the component mounts
//     fetchTransactions();
//     fetchAvailableBalance();
//   }, []);

//   /**
//    * Filters the transactions based on the selected date range
//    */
//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions); // If no date is selected, reset to all transactions
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered); // Update the filtered transactions state
//       setChartData(transformDataForChart(filtered)); // Update the chart data based on filtered transactions
//       calculateTransactionStats(filtered); // Recalculate stats based on filtered data
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate(); // Apply filtering when the date range changes
//   }, [startDate, endDate, transactions]);

//   /**
//    * Transforms the fetched transaction data into a format suitable for the chart.
//    */
//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   /**
//    * Calculate the number of success, pending, and failed transactions along with their total amounts
//    */
//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   // Conditional rendering based on loading or error states
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="header-left">
//           <div className="welcome-message">
//             <h2>Welcome, {merchantName}</h2>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="available-balance" style={{ marginLeft: '20px' }}>
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//         </div>
//         <div className="date-selector">
//           <input
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             aria-label="Start Date"
//           />
//           <span> to </span>
//           <input
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             aria-label="End Date"
//           />
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <ResponsiveContainer width="100%" height={400}>
//             <LineChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="date" tick={{ fontSize: 14 }} interval={0} />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </ResponsiveContainer>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;





// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]); // Holds all transaction data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions for chart
//   const [chartData, setChartData] = useState([]); // Data for the chart
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(''); // Error message state
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [availableBalance, setAvailableBalance] = useState(0); // Available balance for the merchant
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 }); // Success transactions count and total amount
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 }); // Pending transactions count and total amount
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 }); // Failed transactions count and total amount

//   const merchantId = localStorage.getItem('merchantId'); // Merchant ID fetched from local storage

//   // Fetch all transactions from the backend
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions); // Update state with the fetched transactions
//         setFilteredTransactions(data.transactions); // Initialize filtered transactions
//         setChartData(transformDataForChart(data.transactions)); // Transform and set chart data
//         calculateTransactionStats(data.transactions); // Calculate Success, Pending, Failed transactions
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch the available balance from the settlements API
//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.settlements.length > 0) {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount), // Properly sum amounts as floats
//           0
//         );
//         setAvailableBalance(totalBalance); // Set the available balance from the settlements
//       } else {
//         setAvailableBalance(0); // Set balance to zero if no settlements
//       }
//     } catch (error) {
//       setAvailableBalance(0); // Set balance to zero if there's an error fetching settlements
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     // Fetch transaction and balance data when the component mounts
//     fetchTransactions();
//     fetchAvailableBalance();
//   }, []);

//   /**
//    * Filters the transactions based on the selected date range
//    */
//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions); // If no date is selected, reset to all transactions
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered); // Update the filtered transactions state
//       setChartData(transformDataForChart(filtered)); // Update the chart data based on filtered transactions
//       calculateTransactionStats(filtered); // Recalculate stats based on filtered data
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate(); // Apply filtering when the date range changes
//   }, [startDate, endDate, transactions]);

//   /**
//    * Transforms the fetched transaction data into a format suitable for the chart.
//    */
//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   /**
//    * Calculate the number of success, pending, and failed transactions along with their total amounts
//    */
//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   // Conditional rendering based on loading or error states
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="header-left">
//           <div className="welcome-message">
//             <h2>Welcome, {merchantName}</h2>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="available-balance" style={{ marginLeft: '20px' }}>
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//         </div>
//         <div className="date-selector">
//           <input
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             aria-label="Start Date"
//           />
//           <span> to </span>
//           <input
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             aria-label="End Date"
//           />
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)} // Adjust width based on data length
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }} // Increase bottom margin for rotated labels
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70} // Increase height for rotated labels
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;



// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]); // Holds all transaction data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions for chart
//   const [chartData, setChartData] = useState([]); // Data for the chart
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(''); // Error message state
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [availableBalance, setAvailableBalance] = useState(0); // Available balance for the merchant
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0); // Rolling reserve balance
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 }); // Success transactions count and total amount
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 }); // Pending transactions count and total amount
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 }); // Failed transactions count and total amount

//   const merchantId = localStorage.getItem('merchantId'); // Merchant ID fetched from local storage

//   // Fetch all transactions from the backend
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions); // Update state with the fetched transactions
//         setFilteredTransactions(data.transactions); // Initialize filtered transactions
//         setChartData(transformDataForChart(data.transactions)); // Transform and set chart data
//         calculateTransactionStats(data.transactions); // Calculate Success, Pending, Failed transactions
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch the available balance from the settlements API
//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.settlements.length > 0) {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount), // Properly sum amounts as floats
//           0
//         );
//         setAvailableBalance(totalBalance); // Set the available balance from the settlements
//       } else {
//         setAvailableBalance(0); // Set balance to zero if no settlements
//       }
//     } catch (error) {
//       setAvailableBalance(0); // Set balance to zero if there's an error fetching settlements
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   // Fetch the rolling reserve balance from the rolling reserves API
//   const fetchRollingReserveBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.reserves.length > 0) {
//         const totalRollingReserve = data.reserves.reduce(
//           (acc, reserve) => acc + parseFloat(reserve.amount),
//           0
//         );
//         setRollingReserveBalance(totalRollingReserve); // Set the rolling reserve balance
//       } else {
//         setRollingReserveBalance(0); // Set to zero if no reserves
//       }
//     } catch (error) {
//       setRollingReserveBalance(0); // Set to zero if there's an error fetching reserves
//       setError('An error occurred while fetching the rolling reserve balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     // Fetch transaction, balance, and rolling reserve data when the component mounts
//     fetchTransactions();
//     fetchAvailableBalance();
//     fetchRollingReserveBalance();
//   }, []);

//   /**
//    * Filters the transactions based on the selected date range
//    */
//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions); // If no date is selected, reset to all transactions
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered); // Update the filtered transactions state
//       setChartData(transformDataForChart(filtered)); // Update the chart data based on filtered transactions
//       calculateTransactionStats(filtered); // Recalculate stats based on filtered data
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate(); // Apply filtering when the date range changes
//   }, [startDate, endDate, transactions]);

//   /**
//    * Transforms the fetched transaction data into a format suitable for the chart.
//    */
//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   /**
//    * Calculate the number of success, pending, and failed transactions along with their total amounts
//    */
//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   // Conditional rendering based on loading or error states
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="header-left">
//           <div className="welcome-message">
//             <h2>Welcome, {merchantName}</h2>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="balance-widgets">
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Available Balance</span>
//                 <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//               </div>
//             </div>
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Rolling Reserve</span>
//                 <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//               </div>
//             </div>
//           </div>
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)} // Adjust width based on data length
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }} // Increase bottom margin for rotated labels
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70} // Increase height for rotated labels
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;



// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]); // Holds all transaction data
//   const [filteredTransactions, setFilteredTransactions] = useState([]); // Filtered transactions for chart
//   const [chartData, setChartData] = useState([]); // Data for the chart
//   const [loading, setLoading] = useState(true); // Loading state
//   const [error, setError] = useState(''); // Error message state
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const [availableBalance, setAvailableBalance] = useState(0); // Available balance for the merchant
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0); // Rolling reserve balance
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 }); // Success transactions count and total amount
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 }); // Pending transactions count and total amount
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 }); // Failed transactions count and total amount

//   const merchantId = localStorage.getItem('merchantId'); // Merchant ID fetched from local storage

//   // Fetch all transactions from the backend
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions); // Update state with the fetched transactions
//         setFilteredTransactions(data.transactions); // Initialize filtered transactions
//         setChartData(transformDataForChart(data.transactions)); // Transform and set chart data
//         calculateTransactionStats(data.transactions); // Calculate Success, Pending, Failed transactions
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch the available balance from the settlements API
//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.settlements.length > 0) {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount), // Properly sum amounts as floats
//           0
//         );
//         setAvailableBalance(totalBalance); // Set the available balance from the settlements
//       } else {
//         setAvailableBalance(0); // Set balance to zero if no settlements
//       }
//     } catch (error) {
//       setAvailableBalance(0); // Set balance to zero if there's an error fetching settlements
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   // Fetch the rolling reserve balance from the rolling reserves API
//   const fetchRollingReserveBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.reserves.length > 0) {
//         const totalRollingReserve = data.reserves.reduce(
//           (acc, reserve) => acc + parseFloat(reserve.amount),
//           0
//         );
//         setRollingReserveBalance(totalRollingReserve); // Set the rolling reserve balance
//       } else {
//         setRollingReserveBalance(0); // Set to zero if no reserves
//       }
//     } catch (error) {
//       setRollingReserveBalance(0); // Set to zero if there's an error fetching reserves
//       setError('An error occurred while fetching the rolling reserve balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     // Fetch transaction, balance, and rolling reserve data when the component mounts
//     fetchTransactions();
//     fetchAvailableBalance();
//     fetchRollingReserveBalance();
//   }, []);

//   /**
//    * Filters the transactions based on the selected date range
//    */
//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions); // If no date is selected, reset to all transactions
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered); // Update the filtered transactions state
//       setChartData(transformDataForChart(filtered)); // Update the chart data based on filtered transactions
//       calculateTransactionStats(filtered); // Recalculate stats based on filtered data
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate(); // Apply filtering when the date range changes
//   }, [startDate, endDate, transactions]);

//   /**
//    * Transforms the fetched transaction data into a format suitable for the chart.
//    */
//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   /**
//    * Calculate the number of success, pending, and failed transactions along with their total amounts
//    */
//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0); // Properly summing amounts

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   // Conditional rendering based on loading or error states
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="header-left">
//           <div className="welcome-message">
//             <h2>Welcome, {merchantName}</h2>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="balance-widgets">
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Available Balance</span>
//                 <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//               </div>
//             </div>
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Rolling Reserve Balance</span>
//                 <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//               </div>
//             </div>
//           </div>
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)} // Adjust width based on data length
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }} // Increase bottom margin for rotated labels
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70} // Increase height for rotated labels
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;


// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [chartData, setChartData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 });

//   const merchantId = localStorage.getItem('merchantId');

//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions);
//         setChartData(transformDataForChart(data.transactions));
//         calculateTransactionStats(data.transactions);
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.settlements.length > 0) {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount),
//           0
//         );
//         setAvailableBalance(totalBalance);
//       } else {
//         setAvailableBalance(0);
//       }
//     } catch (error) {
//       setAvailableBalance(0);
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   const fetchRollingReserveBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.reserves.length > 0) {
//         const totalRollingReserve = data.reserves.reduce(
//           (acc, reserve) => acc + parseFloat(reserve.amount),
//           0
//         );
//         setRollingReserveBalance(totalRollingReserve);
//       } else {
//         setRollingReserveBalance(0);
//       }
//     } catch (error) {
//       setRollingReserveBalance(0);
//       setError('An error occurred while fetching the rolling reserve balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     fetchTransactions();
//     fetchAvailableBalance();
//     fetchRollingReserveBalance();
//   }, []);

//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions);
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered);
//       setChartData(transformDataForChart(filtered));
//       calculateTransactionStats(filtered);
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate();
//   }, [startDate, endDate, transactions]);

//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   if (loading) {
//     return (
//       <div className="loading-spinner">
//         <div className="spinner"></div>
//         <p>Loading Dashboard...</p>
//       </div>
//     );
//   }

//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="header-left">
//           <div className="welcome-message">
//             <h2>Welcome, {merchantName}</h2>
//           </div>
//           <div className="balance-widgets">
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Available Balance</span>
//                 <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//               </div>
//             </div>
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Rolling Reserve Balance</span>
//                 <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)}
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70}
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;


// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [chartData, setChartData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [settlementBalance, setSettlementBalance] = useState(0);
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 });

//   const merchantId = localStorage.getItem('merchantId');

//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions);
//         setChartData(transformDataForChart(data.transactions));
//         calculateTransactionStats(data.transactions);
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.settlements.length > 0) {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount),
//           0
//         );
//         setAvailableBalance(totalBalance);
//       } else {
//         setAvailableBalance(0);
//       }
//     } catch (error) {
//       setAvailableBalance(0);
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   const fetchSettlementBalance = async () => {
//     try {
//       // Assuming settlement balance is the same as available balance
//       // If it's different, adjust this function to fetch the correct data
//       setSettlementBalance(availableBalance);
//     } catch (error) {
//       setSettlementBalance(0);
//       setError('An error occurred while fetching the settlement balance.');
//     }
//   };

//   const fetchRollingReserveBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.reserves.length > 0) {
//         const totalRollingReserve = data.reserves.reduce(
//           (acc, reserve) => acc + parseFloat(reserve.amount),
//           0
//         );
//         setRollingReserveBalance(totalRollingReserve);
//       } else {
//         setRollingReserveBalance(0);
//       }
//     } catch (error) {
//       setRollingReserveBalance(0);
//       setError('An error occurred while fetching the rolling reserve balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     fetchTransactions();
//     fetchAvailableBalance();
//     fetchSettlementBalance();
//     fetchRollingReserveBalance();
//   }, []);

//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions);
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered);
//       setChartData(transformDataForChart(filtered));
//       calculateTransactionStats(filtered);
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate();
//   }, [startDate, endDate, transactions]);

//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   if (loading) {
//     return (
//       <div className="loading-spinner">
//         <div className="spinner"></div>
//         <p>Loading Dashboard...</p>
//       </div>
//     );
//   }

//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="welcome-message">
//           <h2>Welcome, {merchantName}</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Settlement</span>
//               <span className="balance-amount">₹{settlementBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Rolling Reserve</span>
//               <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)}
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70}
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;



// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [chartData, setChartData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [settlementBalance, setSettlementBalance] = useState(0);
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 });

//   const merchantId = localStorage.getItem('merchantId');

//   // Fetch transactions
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions);
//         setChartData(transformDataForChart(data.transactions));
//         calculateTransactionStats(data.transactions);
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch available and rolling reserve balances
//   const fetchBalances = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setAvailableBalance(parseFloat(data.available_balance));
//         setRollingReserveBalance(parseFloat(data.rolling_reserve_balance));
//       } else {
//         setError('Failed to load balances.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching balances.');
//     }
//   };

//   const fetchSettlementBalance = async () => {
//     try {
//       // Assuming settlement balance is the same as available balance
//       setSettlementBalance(availableBalance);
//     } catch (error) {
//       setSettlementBalance(0);
//       setError('An error occurred while fetching the settlement balance.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     fetchTransactions();
//     fetchBalances();
//     fetchSettlementBalance();
//   }, []);

//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions);
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered);
//       setChartData(transformDataForChart(filtered));
//       calculateTransactionStats(filtered);
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate();
//   }, [startDate, endDate, transactions]);

//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? txn.amount : 0,
//         Pending: txn.status === 'PENDING' ? txn.amount : 0,
//         Failed: txn.status === 'FAILED' ? txn.amount : 0,
//       });
//     });
//     return data;
//   };

//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   if (loading) {
//     return (
//       <div className="loading-spinner">
//         <div className="spinner"></div>
//         <p>Loading Dashboard...</p>
//       </div>
//     );
//   }

//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="welcome-message">
//           <h2>Welcome, {merchantName}</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Settlement</span>
//               <span className="balance-amount">₹{settlementBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Rolling Reserve</span>
//               <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)}
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70}
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;


// frontend/src/components/Dashboard.js

// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [chartData, setChartData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [settlementBalance, setSettlementBalance] = useState(0);
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 });

//   const merchantId = localStorage.getItem('merchantId');

//   // Fetch transactions
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions);
//         setChartData(transformDataForChart(data.transactions));
//         calculateTransactionStats(data.transactions);
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch balances
//   const fetchBalances = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setAvailableBalance(parseFloat(data.available_balance));
//         setRollingReserveBalance(parseFloat(data.rolling_reserve_balance));
//         setSettlementBalance(parseFloat(data.settlement_balance)); // Fetch settlement_balance
//       } else {
//         setError('Failed to load balances.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching balances.');
//     }
//   };

//   useEffect(() => {
//     fetchTransactions();
//     fetchBalances();
//   }, []);

//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions);
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered);
//       setChartData(transformDataForChart(filtered));
//       calculateTransactionStats(filtered);
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate();
//   }, [startDate, endDate, transactions]);

//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? parseFloat(txn.amount) : 0,
//         Pending: txn.status === 'PENDING' ? parseFloat(txn.amount) : 0,
//         Failed: txn.status === 'FAILED' ? parseFloat(txn.amount) : 0,
//       });
//     });
//     return data;
//   };

//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   if (loading) {
//     return (
//       <div className="loading-spinner">
//         <div className="spinner"></div>
//         <p>Loading Dashboard...</p>
//       </div>
//     );
//   }

//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="welcome-message">
//           <h2>Welcome, {merchantName}</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Settlement Balance</span>
//               <span className="balance-amount">₹{settlementBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Rolling Reserve</span>
//               <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)}
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70}
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;



// frontend/src/components/Dashboard.js

// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [chartData, setChartData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [settlementBalance, setSettlementBalance] = useState(0);
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 });

//   const merchantId = localStorage.getItem('merchantId');

//   // Fetch transactions
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions);
//         setChartData(transformDataForChart(data.transactions));
//         calculateTransactionStats(data.transactions);
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch balances
//   const fetchBalances = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setAvailableBalance(parseFloat(data.available_balance));
//         setRollingReserveBalance(parseFloat(data.rolling_reserve_balance));
//         setSettlementBalance(parseFloat(data.settlement_balance));
//       } else {
//         setError('Failed to load balances.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching balances.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     fetchTransactions();
//     fetchBalances();
//   }, []);

//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions);
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered);
//       setChartData(transformDataForChart(filtered));
//       calculateTransactionStats(filtered);
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate();
//   }, [startDate, endDate, transactions]);

//   const transformDataForChart = (transactions) => {
//     const data = [];
//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at).toLocaleString();
//       data.push({
//         date: txnDate,
//         Success: txn.status === 'SUCCESS' ? parseFloat(txn.amount) : 0,
//         Pending: txn.status === 'PENDING' ? parseFloat(txn.amount) : 0,
//         Failed: txn.status === 'FAILED' ? parseFloat(txn.amount) : 0,
//       });
//     });
//     return data;
//   };

//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   if (loading) {
//     return (
//       <div className="loading-spinner">
//         <div className="spinner"></div>
//         <p>Loading Dashboard...</p>
//       </div>
//     );
//   }

//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="welcome-message">
//           <h2>Welcome, {merchantName}</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Settlement Balance</span>
//               <span className="balance-amount">₹{settlementBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Rolling Reserve</span>
//               <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <div className="chart-scrollable">
//             <LineChart
//               width={Math.max(chartData.length * 80, 600)}
//               height={400}
//               data={chartData}
//               margin={{ top: 20, right: 30, left: 0, bottom: 50 }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 angle={-45}
//                 textAnchor="end"
//                 height={70}
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;




// src/components/Dashboard.js

// import React, { useState, useEffect } from 'react';
// import '../styles/Dashboard.css';
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from 'recharts';

// const Dashboard = () => {
//   const [merchantName, setMerchantName] = useState('');
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [chartData, setChartData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [settlementBalance, setSettlementBalance] = useState(0);
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 });
//   const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 });

//   const merchantId = localStorage.getItem('merchantId');

//   // Fetch transactions
//   const fetchTransactions = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//         setFilteredTransactions(data.transactions);
//         setChartData(transformDataForChart(data.transactions));
//         calculateTransactionStats(data.transactions);
//       } else {
//         setError('Failed to load transaction data.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching data.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch balances
//   const fetchBalances = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setAvailableBalance(parseFloat(data.available_balance));
//         setRollingReserveBalance(parseFloat(data.rolling_reserve_balance));
//         setSettlementBalance(parseFloat(data.settlement_balance));
//       } else {
//         setError('Failed to load balances.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching balances.');
//     }
//   };

//   useEffect(() => {
//     const storedMerchantName = localStorage.getItem('merchantName');
//     setMerchantName(storedMerchantName || 'Merchant');

//     fetchTransactions();
//     fetchBalances();
//   }, []);

//   const filterTransactionsByDate = () => {
//     if (!startDate || !endDate) {
//       setFilteredTransactions(transactions);
//       setChartData(transformDataForChart(transactions));
//       calculateTransactionStats(transactions);
//     } else {
//       const filtered = transactions.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//       setFilteredTransactions(filtered);
//       setChartData(transformDataForChart(filtered));
//       calculateTransactionStats(filtered);
//     }
//   };

//   useEffect(() => {
//     filterTransactionsByDate();
//   }, [startDate, endDate, transactions]);

//   // Transform data for the chart by aggregating amounts per date and status
//   const transformDataForChart = (transactions) => {
//     const dataMap = {};

//     transactions.forEach((txn) => {
//       const txnDate = new Date(txn.created_at);
//       const dateKey = txnDate.toISOString().split('T')[0]; // 'YYYY-MM-DD' format

//       if (!dataMap[dateKey]) {
//         dataMap[dateKey] = { date: dateKey, Success: 0, Pending: 0, Failed: 0 };
//       }

//       const amount = parseFloat(txn.amount);

//       if (txn.status === 'SUCCESS') {
//         dataMap[dateKey].Success += amount;
//       } else if (txn.status === 'PENDING') {
//         dataMap[dateKey].Pending += amount;
//       } else if (txn.status === 'FAILED') {
//         dataMap[dateKey].Failed += amount;
//       }
//     });

//     // Convert dataMap to an array and sort by date
//     const dataArray = Object.values(dataMap).sort((a, b) => new Date(a.date) - new Date(b.date));

//     return dataArray;
//   };

//   const calculateTransactionStats = (transactions) => {
//     const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
//     const successAmount = transactions
//       .filter((txn) => txn.status === 'SUCCESS')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
//     const pendingAmount = transactions
//       .filter((txn) => txn.status === 'PENDING')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
//     const failedAmount = transactions
//       .filter((txn) => txn.status === 'FAILED')
//       .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

//     setSuccessTransactions({ count: successCount, totalAmount: successAmount });
//     setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
//     setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
//   };

//   if (loading) {
//     return (
//       <div className="loading-spinner">
//         <div className="spinner"></div>
//         <p>Loading Dashboard...</p>
//       </div>
//     );
//   }

//   if (error) {
//     return <div className="error-message">{error}</div>;
//   }

//   return (
//     <div className="dashboard-content">
//       <header className="dashboard-header">
//         <div className="welcome-message">
//           <h2>Welcome, {merchantName}</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Available Balance</span>
//               <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Settlement Balance</span>
//               <span className="balance-amount">₹{settlementBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Rolling Reserve</span>
//               <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               aria-label="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               aria-label="End Date"
//             />
//           </div>
//         </div>
//       </header>

//       {/* Success, Pending, Failed Transactions Section */}
//       <section className="dashboard-stats">
//         <div className="stat-card success">
//           <h3>Success</h3>
//           <p>
//             {successTransactions.count} Transactions<br />
//             ₹{successTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card pending">
//           <h3>Pending</h3>
//           <p>
//             {pendingTransactions.count} Transactions<br />
//             ₹{pendingTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//         <div className="stat-card failed">
//           <h3>Failed</h3>
//           <p>
//             {failedTransactions.count} Transactions<br />
//             ₹{failedTransactions.totalAmount.toLocaleString()}
//           </p>
//         </div>
//       </section>

//       {/* Chart Section */}
//       <section className="chart-container" aria-label="Transaction Chart">
//         <div className="chart-wrapper">
//           <ResponsiveContainer width="100%" height={400}>
//             <LineChart
//               data={chartData}
//               margin={{ top: 50, right: 30, left: 0, bottom: 30 }}
//             >
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis
//                 dataKey="date"
//                 tickFormatter={(date) => {
//                   const options = { month: 'short', day: 'numeric' }; // e.g., 'Apr 1'
//                   return new Date(date).toLocaleDateString(undefined, options);
//                 }}
//                 tick={{ fontSize: 12 }}
//                 interval={0}
//                 height={50}
//               />
//               <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
//               <Tooltip />
//               <Legend verticalAlign="top" height={36} />
//               <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
//               <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
//             </LineChart>
//           </ResponsiveContainer>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Dashboard;


// src/components/Dashboard.js

import React, { useState, useEffect } from 'react';
import '../styles/Dashboard.css';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Dashboard = () => {
  const [merchantName, setMerchantName] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [availableBalance, setAvailableBalance] = useState(0);
  const [settlementBalance, setSettlementBalance] = useState(0);
  const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
  const [successTransactions, setSuccessTransactions] = useState({ count: 0, totalAmount: 0 });
  const [pendingTransactions, setPendingTransactions] = useState({ count: 0, totalAmount: 0 });
  const [failedTransactions, setFailedTransactions] = useState({ count: 0, totalAmount: 0 });

  const merchantId = localStorage.getItem('merchantId');

  // Fetch transactions
  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://payin.payinfy.com/api/transactions/${merchantId}/`);
      const data = await response.json();
      if (data.status === 'Success') {
        setTransactions(data.transactions);
        setFilteredTransactions(data.transactions);
        setChartData(transformDataForChart(data.transactions));
        calculateTransactionStats(data.transactions);
      } else {
        setError('Failed to load transaction data.');
      }
    } catch (error) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch balances
  const fetchBalances = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
      const data = await response.json();
      if (data.status === 'Success') {
        setAvailableBalance(parseFloat(data.available_balance));
        setRollingReserveBalance(parseFloat(data.rolling_reserve_balance));
        setSettlementBalance(parseFloat(data.settlement_balance));
      } else {
        setError('Failed to load balances.');
      }
    } catch (error) {
      setError('An error occurred while fetching balances.');
    }
  };

  useEffect(() => {
    const storedMerchantName = localStorage.getItem('merchantName');
    setMerchantName(storedMerchantName || 'Merchant');

    fetchTransactions();
    fetchBalances();
  }, []);

  const filterTransactionsByDate = () => {
    if (!startDate || !endDate) {
      setFilteredTransactions(transactions);
      setChartData(transformDataForChart(transactions));
      calculateTransactionStats(transactions);
    } else {
      const filtered = transactions.filter((txn) => {
        const txnDate = new Date(txn.created_at);
        return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
      });
      setFilteredTransactions(filtered);
      setChartData(transformDataForChart(filtered));
      calculateTransactionStats(filtered);
    }
  };

  useEffect(() => {
    filterTransactionsByDate();
  }, [startDate, endDate, transactions]);

  // Transform data for the chart by aggregating amounts per date and status
  const transformDataForChart = (transactions) => {
    const dataMap = {};

    transactions.forEach((txn) => {
      const txnDate = new Date(txn.created_at);
      const dateKey = txnDate.toISOString().split('T')[0]; // 'YYYY-MM-DD' format

      if (!dataMap[dateKey]) {
        dataMap[dateKey] = { date: dateKey, Success: 0, Pending: 0, Failed: 0 };
      }

      const amount = parseFloat(txn.amount);

      if (txn.status === 'SUCCESS') {
        dataMap[dateKey].Success += amount;
      } else if (txn.status === 'PENDING') {
        dataMap[dateKey].Pending += amount;
      } else if (txn.status === 'FAILED') {
        dataMap[dateKey].Failed += amount;
      }
    });

    // Convert dataMap to an array and sort by date
    const dataArray = Object.values(dataMap).sort((a, b) => new Date(a.date) - new Date(b.date));

    return dataArray;
  };

  const calculateTransactionStats = (transactions) => {
    const successCount = transactions.filter((txn) => txn.status === 'SUCCESS').length;
    const successAmount = transactions
      .filter((txn) => txn.status === 'SUCCESS')
      .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

    const pendingCount = transactions.filter((txn) => txn.status === 'PENDING').length;
    const pendingAmount = transactions
      .filter((txn) => txn.status === 'PENDING')
      .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

    const failedCount = transactions.filter((txn) => txn.status === 'FAILED').length;
    const failedAmount = transactions
      .filter((txn) => txn.status === 'FAILED')
      .reduce((acc, txn) => acc + parseFloat(txn.amount), 0);

    setSuccessTransactions({ count: successCount, totalAmount: successAmount });
    setPendingTransactions({ count: pendingCount, totalAmount: pendingAmount });
    setFailedTransactions({ count: failedCount, totalAmount: failedAmount });
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <div className="spinner"></div>
        <p>Loading Dashboard...</p>
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="dashboard-content">
      <header className="dashboard-header">
        <div className="welcome-message">
          <h2>Welcome, {merchantName}</h2>
        </div>
        <div className="balance-widgets">
          <div className="balance-widget">
            <div className="balance-info">
              <span className="balance-label">Available Balance</span>
              <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
            </div>
          </div>
          <div className="balance-widget">
            <div className="balance-info">
              <span className="balance-label">Settlement Balance</span>
              <span className="balance-amount">₹{settlementBalance.toLocaleString()}</span>
            </div>
          </div>
          <div className="balance-widget">
            <div className="balance-info">
              <span className="balance-label">Rolling Reserve</span>
              <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
            </div>
          </div>
          <div className="date-selector">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              aria-label="Start Date"
            />
            <span> to </span>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              aria-label="End Date"
            />
          </div>
        </div>
      </header>

      {/* Success, Pending, Failed Transactions Section */}
      <section className="dashboard-stats">
        <div className="stat-card success">
          <h3>Success</h3>
          <p>
            {successTransactions.count} Transactions<br />
            ₹{successTransactions.totalAmount.toLocaleString()}
          </p>
        </div>
        <div className="stat-card pending">
          <h3>Pending</h3>
          <p>
            {pendingTransactions.count} Transactions<br />
            ₹{pendingTransactions.totalAmount.toLocaleString()}
          </p>
        </div>
        <div className="stat-card failed">
          <h3>Failed</h3>
          <p>
            {failedTransactions.count} Transactions<br />
            ₹{failedTransactions.totalAmount.toLocaleString()}
          </p>
        </div>
      </section>

      {/* Chart Section */}
      <section className="chart-container" aria-label="Transaction Chart">
        <div className="chart-wrapper">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartData}
              margin={{ top: 50, right: 30, left: 0, bottom: 30 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(date) => {
                  const options = { month: 'short', day: 'numeric' }; // e.g., 'Apr 1'
                  return new Date(date).toLocaleDateString(undefined, options);
                }}
                tick={{ fontSize: 12 }}
                interval={0}
                height={50}
              />
              <YAxis allowDecimals={false} tick={{ fontSize: 14 }} />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Line type="monotone" dataKey="Success" stroke="#2ECC71" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="Pending" stroke="#F1C40F" />
              <Line type="monotone" dataKey="Failed" stroke="#E74C3C" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;



