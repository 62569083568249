// // src/components/RollingReserve.js

// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css';
// import { FiDownload, FiSearch } from 'react-icons/fi'; // Import icons

// const RollingReserve = () => {
//   // **State Variables**
//   const [reserves] = useState([]); // Holds all rolling reserve data
//   const [filteredReserves, setFilteredReserves] = useState([]); // Reserves filtered by search
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const reservesPerPage = 10; // Number of reserves per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering

//   /**
//    * useEffect Hook
//    *
//    * This hook runs when the component mounts.
//    * Backend Developer: Replace the code inside this hook with an API call to fetch rolling reserve data.
//    */
//   useEffect(() => {
//     // TODO: Fetch rolling reserve data from the backend API and update the 'reserves' state.
//     // Example:
//     // fetch('/api/rolling-reserve')
//     //   .then(response => response.json())
//     //   .then(data => {
//     //     setReserves(data);
//     //     setFilteredReserves(data);
//     //   })
//     //   .catch(error => {
//     //     console.error('Error fetching reserves:', error);
//     //     // Handle error state if needed
//     //   });

//     // For now, we'll initialize 'filteredReserves' with an empty array
//     setFilteredReserves([]);
//   }, []);

//   // **Handle Search Input**
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterReserves(term);
//   };

//   // **Filter Reserves**
//   const filterReserves = (term) => {
//     let filtered = reserves;

//     // Filter by search term
//     if (term) {
//       filtered = filtered.filter(
//         (reserve) =>
//           reserve.amount.toString().includes(term) ||
//           reserve.status.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     setFilteredReserves(filtered);
//     setCurrentPage(1); // Reset to first page when filters change
//   };

//   // **Pagination Logic**
//   const indexOfLastReserve = currentPage * reservesPerPage;
//   const indexOfFirstReserve = indexOfLastReserve - reservesPerPage;
//   const currentReserves = filteredReserves.slice(
//     indexOfFirstReserve,
//     indexOfLastReserve
//   );
//   const totalPages = Math.ceil(filteredReserves.length / reservesPerPage);

//   // **Handle Page Change**
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // **Handle Download Reserves**
//   const handleDownload = () => {
//     // TODO: Implement actual download logic to export reserves data
//     alert('Download functionality to be implemented.');
//   };

//   return (
//     <div className="rolling-reserve-content">
//       {/* Header Section */}
//       <header className="rolling-reserve-header">
//         <div className="rolling-reserve-title">
//           <h2>Rolling Reserve</h2>
//         </div>
//         <div className="date-selector">
//           <label htmlFor="start-date" className="visually-hidden">
//             Start Date
//           </label>
//           <input
//             type="date"
//             id="start-date"
//             aria-label="Start Date"
//             // TODO: Implement date selection functionality
//           />
//           <span> to </span>
//           <label htmlFor="end-date" className="visually-hidden">
//             End Date
//           </label>
//           <input
//             type="date"
//             id="end-date"
//             aria-label="End Date"
//             // TODO: Implement date selection functionality
//           />
//           {/* Backend Developer: Implement date filtering functionality based on selected dates */}
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="rolling-reserve-action-bar">
//         {/* Placeholder for alignment */}
//         <div className="rolling-reserve-tabs"></div>

//         {/* Search and Download */}
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search reserves"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <button className="download-button" onClick={handleDownload}>
//             <FiDownload className="download-icon" />
//             Download
//           </button>
//         </div>
//       </div>

//       {/* Reserves Table */}
//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Ageing</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentReserves.length > 0 ? (
//                 currentReserves.map((reserve) => (
//                   <tr key={reserve.id}>
//                     <td>{reserve.dateTime}</td>
//                     <td>₹{reserve.amount}</td>
//                     <td>{reserve.ageing}</td>
//                     <td>
//                       <span className={`status ${reserve.status.toLowerCase()}`}>
//                         {reserve.status}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="4" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No reserves found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredReserves.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RollingReserve;


// src/components/RollingReserve.js

// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css'; // Import styles for rolling reserve page
// import { FiDownload, FiSearch } from 'react-icons/fi'; // Import icons
// import { CSVLink } from 'react-csv'; // For CSV download functionality

// const RollingReserve = () => {
//   const [reserves, setReserves] = useState([]); // Holds all reserves data
//   const [filteredReserves, setFilteredReserves] = useState([]); // Reserves filtered by search and date
//   const [currentPage, setCurrentPage] = useState(1); // Current page number for pagination
//   const reservesPerPage = 10; // Number of reserves per page
//   const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering
//   const [startDate, setStartDate] = useState(''); // Start date for filtering
//   const [endDate, setEndDate] = useState(''); // End date for filtering
//   const merchantId = localStorage.getItem('merchantId'); // Get merchantId from localStorage

//   /**
//    * Fetch reserves data from the backend API when the component mounts.
//    */
//   useEffect(() => {
//     fetchReserves();
//     const intervalId = setInterval(fetchReserves, 5000); // Poll the backend every 5 seconds for real-time updates
//     return () => clearInterval(intervalId); // Cleanup on component unmount
//   }, []);

//   /**
//    * Function to fetch rolling reserves data from the backend API.
//    */
//   const fetchReserves = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setReserves(data.reserves);
//         setFilteredReserves(data.reserves); // Initialize filtered reserves
//       } else {
//         console.error('Error fetching reserves:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching reserves:', error);
//     }
//   };

//   /**
//    * Handle search input changes and filter reserves based on search term and date.
//    */
//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterReserves(term, startDate, endDate);
//   };

//   /**
//    * Function to filter reserves by search term and date range.
//    */
//   const filterReserves = (term, start, end) => {
//     let filtered = reserves;

//     // Filter by search term (amount, status)
//     if (term) {
//       filtered = filtered.filter(
//         (reserve) =>
//           reserve.amount.toString().includes(term) ||
//           reserve.status.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     // Filter by date range
//     if (start && end) {
//       filtered = filtered.filter((reserve) => {
//         const reserveDate = new Date(reserve.dateTime);
//         return reserveDate >= new Date(start) && reserveDate <= new Date(end);
//       });
//     }

//     setFilteredReserves(filtered);
//     setCurrentPage(1); // Reset to the first page when filters change
//   };

//   /**
//    * Handle date changes and filter reserves by date range.
//    */
//   const handleDateChange = (start, end) => {
//     setStartDate(start);
//     setEndDate(end);
//     filterReserves(searchTerm, start, end);
//   };

//   /**
//    * Pagination logic: Calculate current page reserves.
//    */
//   const indexOfLastReserve = currentPage * reservesPerPage;
//   const indexOfFirstReserve = indexOfLastReserve - reservesPerPage;
//   const currentReserves = filteredReserves.slice(indexOfFirstReserve, indexOfLastReserve);
//   const totalPages = Math.ceil(filteredReserves.length / reservesPerPage);

//   /**
//    * Handle page change for pagination.
//    */
//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   /**
//    * Define the headers for CSV download.
//    */
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'dateTime' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Ageing', key: 'ageing' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="rolling-reserve-content">
//       {/* Header Section */}
//       <header className="rolling-reserve-header">
//         <div className="rolling-reserve-title">
//           <h2>Rolling Reserve</h2>
//         </div>
//         <div className="date-selector">
//           <label htmlFor="start-date" className="visually-hidden">
//             Start Date
//           </label>
//           <input
//             type="date"
//             id="start-date"
//             aria-label="Start Date"
//             value={startDate}
//             onChange={(e) => handleDateChange(e.target.value, endDate)}
//           />
//           <span> to </span>
//           <label htmlFor="end-date" className="visually-hidden">
//             End Date
//           </label>
//           <input
//             type="date"
//             id="end-date"
//             aria-label="End Date"
//             value={endDate}
//             onChange={(e) => handleDateChange(startDate, e.target.value)}
//           />
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="rolling-reserve-action-bar">
//         <div className="rolling-reserve-tabs"></div>

//         {/* Search and Download */}
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search reserves"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <CSVLink data={filteredReserves} headers={csvHeaders} filename="reserves.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" />
//               Download
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Reserves Table */}
//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Ageing</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentReserves.length > 0 ? (
//                 currentReserves.map((reserve, index) => (
//                   <tr key={index}>
//                     <td>{new Date(reserve.dateTime).toLocaleString()}</td>
//                     <td>₹{reserve.amount.toFixed(2)}</td>
//                     <td>{reserve.ageing} days</td>
//                     <td>
//                       <span className={`status ${reserve.status.toLowerCase()}`}>
//                         {reserve.status}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="4" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No reserves found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredReserves.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RollingReserve;


// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css';
// import { FiDownload, FiSearch } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// const RollingReserve = () => {
//   const [reserves, setReserves] = useState([]);
//   const [filteredReserves, setFilteredReserves] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const reservesPerPage = 10;
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [availableBalance, setAvailableBalance] = useState(0);
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [error, setError] = useState('');
//   const merchantId = localStorage.getItem('merchantId');

//   useEffect(() => {
//     fetchReserves();
//     fetchAvailableBalance();
//     fetchRollingReserveBalance();
//     const intervalId = setInterval(fetchReserves, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   const fetchReserves = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setReserves(data.reserves);
//         setFilteredReserves(data.reserves);
//       } else {
//         console.error('Error fetching reserves:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching reserves:', error);
//     }
//   };

//   const fetchAvailableBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/settlements/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.settlements.length > 0) {
//         const totalBalance = data.settlements.reduce(
//           (acc, settlement) => acc + parseFloat(settlement.settlementAmount),
//           0
//         );
//         setAvailableBalance(totalBalance);
//       } else {
//         setAvailableBalance(0);
//       }
//     } catch (error) {
//       setAvailableBalance(0);
//       setError('An error occurred while fetching the available balance.');
//     }
//   };

//   const fetchRollingReserveBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.reserves.length > 0) {
//         const totalRollingReserve = data.reserves.reduce(
//           (acc, reserve) => acc + parseFloat(reserve.amount),
//           0
//         );
//         setRollingReserveBalance(totalRollingReserve);
//       } else {
//         setRollingReserveBalance(0);
//       }
//     } catch (error) {
//       setRollingReserveBalance(0);
//       setError('An error occurred while fetching the rolling reserve balance.');
//     }
//   };

//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterReserves(term, startDate, endDate);
//   };

//   const filterReserves = (term, start, end) => {
//     let filtered = reserves;

//     if (term) {
//       filtered = filtered.filter(
//         (reserve) =>
//           reserve.amount.toString().includes(term) ||
//           reserve.status.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     if (start && end) {
//       filtered = filtered.filter((reserve) => {
//         const reserveDate = new Date(reserve.dateTime);
//         return reserveDate >= new Date(start) && reserveDate <= new Date(end);
//       });
//     }

//     setFilteredReserves(filtered);
//     setCurrentPage(1);
//   };

//   const handleDateChange = (start, end) => {
//     setStartDate(start);
//     setEndDate(end);
//     filterReserves(searchTerm, start, end);
//   };

//   const indexOfLastReserve = currentPage * reservesPerPage;
//   const indexOfFirstReserve = indexOfLastReserve - reservesPerPage;
//   const currentReserves = filteredReserves.slice(indexOfFirstReserve, indexOfLastReserve);
//   const totalPages = Math.ceil(filteredReserves.length / reservesPerPage);

//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   const csvHeaders = [
//     { label: 'Date & Time', key: 'dateTime' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Ageing', key: 'ageing' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="rolling-reserve-content">
//       {/* Header Section */}
//       <header className="rolling-reserve-header">
//         <div className="header-left">
//           <div className="rolling-reserve-title">
//             <h2>Rolling Reserve</h2>
//           </div>
//           <div className="balance-widgets">
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Available Balance</span>
//                 <span className="balance-amount">₹{availableBalance.toLocaleString()}</span>
//               </div>
//             </div>
//             <div className="balance-widget">
//               <div className="balance-info">
//                 <span className="balance-label">Rolling Reserve Balance</span>
//                 <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="header-right">
//           <div className="date-selector">
//             <label htmlFor="start-date" className="visually-hidden">
//               Start Date
//             </label>
//             <input
//               type="date"
//               id="start-date"
//               aria-label="Start Date"
//               value={startDate}
//               onChange={(e) => handleDateChange(e.target.value, endDate)}
//             />
//             <span> to </span>
//             <label htmlFor="end-date" className="visually-hidden">
//               End Date
//             </label>
//             <input
//               type="date"
//               id="end-date"
//               aria-label="End Date"
//               value={endDate}
//               onChange={(e) => handleDateChange(startDate, e.target.value)}
//             />
//           </div>
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="rolling-reserve-action-bar">
//         <div className="rolling-reserve-tabs"></div>

//         {/* Search and Download */}
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search reserves"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <CSVLink data={filteredReserves} headers={csvHeaders} filename="reserves.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" />
//               Download
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Reserves Table */}
//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Ageing</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentReserves.length > 0 ? (
//                 currentReserves.map((reserve, index) => (
//                   <tr key={index}>
//                     <td>{new Date(reserve.dateTime).toLocaleString()}</td>
//                     <td>₹{reserve.amount.toFixed(2)}</td>
//                     <td>{reserve.ageing} days</td>
//                     <td>
//                       <span className={`status ${reserve.status.toLowerCase()}`}>
//                         {reserve.status}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="4" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No reserves found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredReserves.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RollingReserve;


// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css';
// import { FiDownload, FiSearch } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// const RollingReserve = () => {
//   const [reserves, setReserves] = useState([]);
//   const [filteredReserves, setFilteredReserves] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const reservesPerPage = 10;
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [error, setError] = useState('');
//   const merchantId = localStorage.getItem('merchantId');

//   useEffect(() => {
//     fetchReserves();
//     fetchRollingReserveBalance();
//     const intervalId = setInterval(fetchReserves, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   const fetchReserves = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setReserves(data.reserves);
//         setFilteredReserves(data.reserves);
//       } else {
//         console.error('Error fetching reserves:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching reserves:', error);
//     }
//   };

//   const fetchRollingReserveBalance = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success' && data.reserves.length > 0) {
//         const totalRollingReserve = data.reserves.reduce(
//           (acc, reserve) => acc + parseFloat(reserve.amount),
//           0
//         );
//         setRollingReserveBalance(totalRollingReserve);
//       } else {
//         setRollingReserveBalance(0);
//       }
//     } catch (error) {
//       setRollingReserveBalance(0);
//       setError('An error occurred while fetching the rolling reserve balance.');
//     }
//   };

//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterReserves(term, startDate, endDate);
//   };

//   const filterReserves = (term, start, end) => {
//     let filtered = reserves;

//     if (term) {
//       filtered = filtered.filter(
//         (reserve) =>
//           reserve.amount.toString().includes(term) ||
//           reserve.status.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     if (start && end) {
//       filtered = filtered.filter((reserve) => {
//         const reserveDate = new Date(reserve.dateTime);
//         return reserveDate >= new Date(start) && reserveDate <= new Date(end);
//       });
//     }

//     setFilteredReserves(filtered);
//     setCurrentPage(1);
//   };

//   const handleDateChange = (start, end) => {
//     setStartDate(start);
//     setEndDate(end);
//     filterReserves(searchTerm, start, end);
//   };

//   const indexOfLastReserve = currentPage * reservesPerPage;
//   const indexOfFirstReserve = indexOfLastReserve - reservesPerPage;
//   const currentReserves = filteredReserves.slice(indexOfFirstReserve, indexOfLastReserve);
//   const totalPages = Math.ceil(filteredReserves.length / reservesPerPage);

//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   const csvHeaders = [
//     { label: 'Date & Time', key: 'dateTime' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Ageing', key: 'ageing' },
//     { label: 'Status', key: 'status' },
//   ];

//   return (
//     <div className="rolling-reserve-content">
//       {/* Header Section */}
//       <header className="rolling-reserve-header">
//         <div className="rolling-reserve-title">
//           <h2>Rolling Reserve</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Rolling Reserve Balance</span>
//               <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <label htmlFor="start-date" className="visually-hidden">
//               Start Date
//             </label>
//             <input
//               type="date"
//               id="start-date"
//               aria-label="Start Date"
//               value={startDate}
//               onChange={(e) => handleDateChange(e.target.value, endDate)}
//             />
//             <span> to </span>
//             <label htmlFor="end-date" className="visually-hidden">
//               End Date
//             </label>
//             <input
//               type="date"
//               id="end-date"
//               aria-label="End Date"
//               value={endDate}
//               onChange={(e) => handleDateChange(startDate, e.target.value)}
//             />
//           </div>
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="rolling-reserve-action-bar">
//         <div className="rolling-reserve-tabs"></div>

//         {/* Search and Download */}
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search reserves"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <CSVLink data={filteredReserves} headers={csvHeaders} filename="reserves.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" />
//               Download
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Reserves Table */}
//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Ageing</th>
//                 <th>Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentReserves.length > 0 ? (
//                 currentReserves.map((reserve, index) => (
//                   <tr key={index}>
//                     <td>{new Date(reserve.dateTime).toLocaleString()}</td>
//                     <td>₹{reserve.amount.toFixed(2)}</td>
//                     <td>{reserve.ageing} days</td>
//                     <td>
//                       <span className={`status ${reserve.status.toLowerCase()}`}>
//                         {reserve.status}
//                       </span>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="4" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No reserves found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredReserves.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RollingReserve;




// import React, { useState, useEffect } from 'react';
// import '../styles/RollingReserve.css';
// import { FiDownload, FiSearch } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// const RollingReserve = () => {
//   const [reserves, setReserves] = useState([]);
//   const [filteredReserves, setFilteredReserves] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const reservesPerPage = 10;
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
//   const [error, setError] = useState('');
//   const merchantId = localStorage.getItem('merchantId');

//   useEffect(() => {
//     fetchReserves();
//     fetchBalances();
//     const intervalId = setInterval(fetchReserves, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   // Fetch rolling reserves
//   const fetchReserves = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setReserves(data.reserves);
//         setFilteredReserves(data.reserves);
//       } else {
//         console.error('Error fetching reserves:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching reserves:', error);
//     }
//   };

//   // Fetch rolling reserve balance
//   const fetchBalances = async () => {
//     try {
//       const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setRollingReserveBalance(parseFloat(data.rolling_reserve_balance));
//       } else {
//         setError('Failed to load balances.');
//       }
//     } catch (error) {
//       setError('An error occurred while fetching balances.');
//     }
//   };

//   const handleSearchChange = (e) => {
//     const term = e.target.value;
//     setSearchTerm(term);
//     filterReserves(term, startDate, endDate);
//   };

//   const filterReserves = (term, start, end) => {
//     let filtered = reserves;

//     if (term) {
//       filtered = filtered.filter(
//         (reserve) =>
//           reserve.amount.toString().includes(term) ||
//           reserve.status.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     if (start && end) {
//       filtered = filtered.filter((reserve) => {
//         const reserveDate = new Date(reserve.dateTime);
//         return reserveDate >= new Date(start) && reserveDate <= new Date(end);
//       });
//     }

//     setFilteredReserves(filtered);
//     setCurrentPage(1);
//   };

//   const handleDateChange = (start, end) => {
//     setStartDate(start);
//     setEndDate(end);
//     filterReserves(searchTerm, start, end);
//   };

//   const indexOfLastReserve = currentPage * reservesPerPage;
//   const indexOfFirstReserve = indexOfLastReserve - reservesPerPage;
//   const currentReserves = filteredReserves.slice(indexOfFirstReserve, indexOfLastReserve);
//   const totalPages = Math.ceil(filteredReserves.length / reservesPerPage);

//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   const csvHeaders = [
//     { label: 'Date & Time', key: 'dateTime' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Ageing', key: 'ageing' },
//     { label: 'Status', key: 'status' },
//     { label: 'Date Settled', key: 'date_settled' }, // New header for CSV export
//   ];

//   return (
//     <div className="rolling-reserve-content">
//       {/* Header Section */}
//       <header className="rolling-reserve-header">
//         <div className="rolling-reserve-title">
//           <h2>Rolling Reserve</h2>
//         </div>
//         <div className="balance-widgets">
//           <div className="balance-widget">
//             <div className="balance-info">
//               <span className="balance-label">Rolling Reserve Balance</span>
//               <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
//             </div>
//           </div>
//           <div className="date-selector">
//             <label htmlFor="start-date" className="visually-hidden">
//               Start Date
//             </label>
//             <input
//               type="date"
//               id="start-date"
//               aria-label="Start Date"
//               value={startDate}
//               onChange={(e) => handleDateChange(e.target.value, endDate)}
//             />
//             <span> to </span>
//             <label htmlFor="end-date" className="visually-hidden">
//               End Date
//             </label>
//             <input
//               type="date"
//               id="end-date"
//               aria-label="End Date"
//               value={endDate}
//               onChange={(e) => handleDateChange(startDate, e.target.value)}
//             />
//           </div>
//         </div>
//       </header>

//       {/* Action Bar */}
//       <div className="rolling-reserve-action-bar">
//         <div className="rolling-reserve-tabs"></div>

//         {/* Search and Download */}
//         <div className="rolling-reserve-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search reserves"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <CSVLink data={filteredReserves} headers={csvHeaders} filename="reserves.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" />
//               Download
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Reserves Table */}
//       <div className="rolling-reserve-table-container">
//         <div className="rolling-reserve-table-wrapper">
//           <table className="rolling-reserve-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Amount</th>
//                 <th>Ageing</th>
//                 <th>Status</th>
//                 <th>Date Settled</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentReserves.length > 0 ? (
//                 currentReserves.map((reserve, index) => (
//                   <tr key={index}>
//                     <td>{new Date(reserve.dateTime).toLocaleString()}</td>
//                     <td>₹{parseFloat(reserve.amount).toFixed(2)}</td>
//                     <td>{reserve.ageing} days</td>
//                     <td>
//                       <span className={`status ${reserve.status.toLowerCase()}`}>
//                         {reserve.status}
//                       </span>
//                     </td>
//                     <td>
//                       {reserve.date_settled ? new Date(reserve.date_settled).toLocaleString() : 'N/A'}
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="5" className="no-reserves">
//                     <div className="no-reserves-message">
//                       <p>No reserves found.</p>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredReserves.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RollingReserve;





import React, { useState, useEffect } from 'react';
import '../styles/RollingReserve.css';
import { FiDownload, FiSearch } from 'react-icons/fi';
import { CSVLink } from 'react-csv';

const RollingReserve = () => {
  const [reserves, setReserves] = useState([]);
  const [filteredReserves, setFilteredReserves] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reservesPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [rollingReserveBalance, setRollingReserveBalance] = useState(0);
  const [error, setError] = useState('');
  const merchantId = localStorage.getItem('merchantId');

  useEffect(() => {
    fetchReserves();
    fetchBalances();
    const intervalId = setInterval(fetchReserves, 5000);
    return () => clearInterval(intervalId);
  }, []);

  // Fetch rolling reserves
  const fetchReserves = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/rolling-reserve/${merchantId}`);
      const data = await response.json();
      if (data.status === 'Success') {
        // Sort by dateTime in descending order
        const sortedReserves = data.reserves.sort(
          (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
        );
        setReserves(sortedReserves);
        setFilteredReserves(sortedReserves);
      } else {
        console.error('Error fetching reserves:', data.message);
      }
    } catch (error) {
      console.error('Error fetching reserves:', error);
    }
  };

  // Fetch rolling reserve balance
  const fetchBalances = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/balances/${merchantId}/`);
      const data = await response.json();
      if (data.status === 'Success') {
        setRollingReserveBalance(parseFloat(data.rolling_reserve_balance));
      } else {
        setError('Failed to load balances.');
      }
    } catch (error) {
      setError('An error occurred while fetching balances.');
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterReserves(term, startDate, endDate);
  };

  const filterReserves = (term, start, end) => {
    let filtered = reserves;

    if (term) {
      filtered = filtered.filter(
        (reserve) =>
          reserve.amount.toString().includes(term) ||
          reserve.status.toLowerCase().includes(term.toLowerCase())
      );
    }

    if (start && end) {
      filtered = filtered.filter((reserve) => {
        const reserveDate = new Date(reserve.dateTime);
        return reserveDate >= new Date(start) && reserveDate <= new Date(end);
      });
    }

    setFilteredReserves(filtered);
    setCurrentPage(1);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterReserves(searchTerm, start, end);
  };

  const indexOfLastReserve = currentPage * reservesPerPage;
  const indexOfFirstReserve = indexOfLastReserve - reservesPerPage;
  const currentReserves = filteredReserves.slice(indexOfFirstReserve, indexOfLastReserve);
  const totalPages = Math.ceil(filteredReserves.length / reservesPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const csvHeaders = [
    { label: 'Date & Time', key: 'dateTime' },
    { label: 'Amount', key: 'amount' },
    { label: 'Ageing', key: 'ageing' },
    { label: 'Status', key: 'status' },
    { label: 'Date Settled', key: 'date_settled' },
  ];

  return (
    <div className="rolling-reserve-content">
      {/* Header Section */}
      <header className="rolling-reserve-header">
        <div className="rolling-reserve-title">
          <h2>Rolling Reserve</h2>
        </div>
        <div className="balance-widgets">
          <div className="balance-widget">
            <div className="balance-info">
              <span className="balance-label">Rolling Reserve Balance</span>
              <span className="balance-amount">₹{rollingReserveBalance.toLocaleString()}</span>
            </div>
          </div>
          <div className="date-selector">
            <label htmlFor="start-date" className="visually-hidden">
              Start Date
            </label>
            <input
              type="date"
              id="start-date"
              aria-label="Start Date"
              value={startDate}
              onChange={(e) => handleDateChange(e.target.value, endDate)}
            />
            <span> to </span>
            <label htmlFor="end-date" className="visually-hidden">
              End Date
            </label>
            <input
              type="date"
              id="end-date"
              aria-label="End Date"
              value={endDate}
              onChange={(e) => handleDateChange(startDate, e.target.value)}
            />
          </div>
        </div>
      </header>

      {/* Action Bar */}
      <div className="rolling-reserve-action-bar">
        <div className="rolling-reserve-tabs"></div>

        {/* Search and Download */}
        <div className="rolling-reserve-actions">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search reserves"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FiSearch className="search-icon" />
          </div>
          <CSVLink data={filteredReserves} headers={csvHeaders} filename="reserves.csv">
            <button className="download-button">
              <FiDownload className="download-icon" />
              Download
            </button>
          </CSVLink>
        </div>
      </div>

      {/* Reserves Table */}
      <div className="rolling-reserve-table-container">
        <div className="rolling-reserve-table-wrapper">
          <table className="rolling-reserve-table">
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>Amount</th>
                <th>Ageing</th>
                <th>Status</th>
                <th>Date Settled</th>
              </tr>
            </thead>
            <tbody>
              {currentReserves.length > 0 ? (
                currentReserves.map((reserve, index) => (
                  <tr key={index}>
                    <td>{new Date(reserve.dateTime).toLocaleString()}</td>
                    <td>₹{parseFloat(reserve.amount).toFixed(2)}</td>
                    <td>{reserve.ageing} days</td>
                    <td>
                      <span className={`status ${reserve.status.toLowerCase()}`}>
                        {reserve.status}
                      </span>
                    </td>
                    <td>
                      {reserve.date_settled ? new Date(reserve.date_settled).toLocaleString() : 'N/A'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="no-reserves">
                    <div className="no-reserves-message">
                      <p>No reserves found.</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          {[...Array(totalPages || 1)].map((_, idx) => (
            <button
              key={idx + 1}
              className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(idx + 1)}
              disabled={filteredReserves.length === 0}
            >
              {idx + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RollingReserve;
