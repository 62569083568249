// src/components/RefundHistory.js

import React, { useState, useEffect } from 'react';
import '../styles/History.css';
import { FiSearch } from 'react-icons/fi';

const RefundHistory = () => {
  const [refunds, setRefunds] = useState([]);
  const [filteredRefunds, setFilteredRefunds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const merchantId = localStorage.getItem('merchantId');

  const fetchRefunds = async () => {
    try {
      const response = await fetch(`https://payin.payinfy.com/api/refund-history/${merchantId}/`);
      const data = await response.json();
      if (data.status === 'Success') {
        setRefunds(data.refunds);
        setFilteredRefunds(data.refunds);
      } else {
        console.error('Failed to fetch refund history');
      }
    } catch (error) {
      console.error('An error occurred while fetching refund history');
    }
  };

  useEffect(() => {
    fetchRefunds();
  }, []);

  // Filter refunds by search term
  useEffect(() => {
    const filtered = refunds.filter(
      (refund) =>
        refund.transaction_id.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRefunds(filtered);
  }, [searchTerm, refunds]);

  return (
    <div className="history-content">
      <div className="history-header">
        <h2>Refund History</h2>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search refunds"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FiSearch className="search-icon" />
        </div>
      </div>
      <div className="history-table-container">
        <table className="history-table">
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Refund Fee</th>
            </tr>
          </thead>
          <tbody>
            {filteredRefunds.length > 0 ? (
              filteredRefunds.map((refund) => (
                <tr key={refund.transaction_id}>
                  <td>{new Date(refund.date).toLocaleString()}</td>
                  <td>{refund.transaction_id}</td>
                  <td>{refund.amount}</td>
                  <td>{refund.refund_fee || 'N/A'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="no-records">
                  No refunds found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RefundHistory;
