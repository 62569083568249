// src/components/AdditionalFunctionality.js

import React from 'react';
import '../styles/AdditionalFunctionality.css';

const AdditionalFunctionality = () => {
  return (
    <div className="additional-functionality-content">
      {/* Header Section */}
      <header className="additional-functionality-header">
        <div className="additional-functionality-title">
          <h2>Additional Functionality</h2>
        </div>
      </header>

      {/* Message Section */}
      <div className="additional-functionality-message-container">
        <div className="message-card">
          <h3>Contact Admin</h3>
          <p>
            If you require any specific functionality or have any questions, please contact our admin.
          </p>
          <p>
            <strong>Telegram:</strong>{' '}
            <a href="https://t.me/Payinfybot">Connect to our Telegram</a>
          </p>
          <p>We are here to assist you with your needs.</p>
        </div>
      </div>
    </div>
  );
};

export default AdditionalFunctionality;
