// src/components/MainContent.js

import React from 'react';
import '../styles/MainContent.css';

/**
 * MainContent Component
 *
 * Serves as the main container for dashboard elements and pages.
 * Styled to match the Sidebar and positioned next to it.
 */
const MainContent = ({ children }) => {
  return (
    <div className="main-content">
      {children}
    </div>
  );
};

export default MainContent;
