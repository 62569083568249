// // src/components/PayoutTransactions.js

// import React, { useState, useEffect } from 'react';
// import '../styles/Transactions.css';
// import Modal from 'react-modal';
// import { FiDownload, FiSearch, FiX } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// // Set the app element for accessibility
// Modal.setAppElement('#root');

// const PayoutTransactions = () => {
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [activeTab, setActiveTab] = useState('All');
//   const [currentPage, setCurrentPage] = useState(1);
//   const transactionsPerPage = 10;
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [selectedTransaction, setSelectedTransaction] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   const merchantId = localStorage.getItem('merchantId');

//   // Fetch payout transactions from the backend API
//   const fetchTransactions = async () => {
//     try {
//       const response = await fetch(`https://payouts.payinfy.com/payouts/transactions/${merchantId}/`);
//       const data = await response.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//       } else {
//         console.error('Error fetching payout transactions:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching payout transactions:', error);
//     }
//   };

//   // Polling to fetch data at regular intervals
//   useEffect(() => {
//     fetchTransactions();
//     const intervalId = setInterval(fetchTransactions, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   // Filter Transactions by Status, Search Term, and Date Range
//   const filterTransactions = () => {
//     let filtered = transactions;

//     // Filter by active tab (status)
//     if (activeTab !== 'All') {
//       filtered = filtered.filter((txn) => txn.status.toLowerCase() === activeTab.toLowerCase());
//     }

//     // Filter by search term
//     if (searchTerm) {
//       filtered = filtered.filter(
//         (txn) =>
//           txn.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.order_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.recipient_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.recipient_account_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.recipient_bank_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           txn.payment_type?.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     // Filter by date range
//     if (startDate && endDate) {
//       filtered = filtered.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//     }

//     setFilteredTransactions(filtered);
//   };

//   // Effect for filter changes
//   useEffect(() => {
//     filterTransactions();
//     setCurrentPage(1);
//   }, [activeTab, searchTerm, startDate, endDate]);

//   // Effect for transactions data update
//   useEffect(() => {
//     filterTransactions();
//   }, [transactions]);

//   // Handle Page Change
//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

//   // Pagination Logic
//   const indexOfLastTransaction = currentPage * transactionsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
//   const currentTransactions = filteredTransactions.slice(
//     indexOfFirstTransaction,
//     indexOfLastTransaction
//   );
//   const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

//   // Open Modal with Transaction Details
//   const openModal = (transaction) => {
//     setSelectedTransaction(transaction);
//     setModalIsOpen(true);
//   };

//   // Close Modal
//   const closeModal = () => {
//     setSelectedTransaction(null);
//     setModalIsOpen(false);
//   };

//   // Download CSV Data
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'created_at' },
//     { label: 'Transaction ID', key: 'transaction_id' },
//     { label: 'Order ID', key: 'order_id' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Currency', key: 'currency' },
//     { label: 'Recipient Name', key: 'recipient_name' },
//     { label: 'Account Number', key: 'recipient_account_number' },
//     { label: 'Bank Name', key: 'recipient_bank_name' },
//     { label: 'IFSC Code', key: 'recipient_ifsc_code' },
//     { label: 'Payment Type', key: 'payment_type' },
//     { label: 'Status', key: 'status' },
//   ];

//   // Prepare data for CSV export
//   const csvData = filteredTransactions.map((txn) => ({
//     ...txn,
//   }));

//   return (
//     <div className="transactions-content">
//       {/* Filter and Action Bar */}
//       <div className="transactions-action-bar">
//         {/* Tabs for Status Filter */}
//         <div className="transactions-tabs">
//           {/* {['All', 'Success', 'Pending', 'Failed', 'Refund'].map((tab) => ( */}
//           {['All', 'Success', 'Failed'].map((tab) => (
//             <button
//               key={tab}
//               className={`tab-button ${activeTab === tab ? 'active' : ''}`}
//               onClick={() => setActiveTab(tab)}
//             >
//               {tab}
//             </button>
//           ))}
//         </div>

//         {/* Search and Date Filter */}
//         <div className="transactions-actions">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search transactions"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//             <FiSearch className="search-icon" />
//           </div>
//           <div className="date-filter">
//             <input
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               placeholder="Start Date"
//             />
//             <span> to </span>
//             <input
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               placeholder="End Date"
//             />
//           </div>
//           <CSVLink data={csvData} headers={csvHeaders} filename="payout_transactions.csv">
//             <button className="download-button">
//               <FiDownload className="download-icon" /> Download CSV
//             </button>
//           </CSVLink>
//         </div>
//       </div>

//       {/* Transactions Table */}
//       <div className="transactions-table-container">
//         <div className="transactions-table-wrapper">
//           <table className="transactions-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Transaction ID</th>
//                 <th>Order ID</th>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 <th>Recipient Name</th>
//                 <th>Account Number</th>
//                 <th>Bank Name</th>
//                 <th>IFSC Code</th>
//                 <th>Payment Type</th>
//                 <th>Status</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransactions.length > 0 ? (
//                 currentTransactions.map((txn) => (
//                   <tr key={txn.transaction_id}>
//                     <td>{new Date(txn.created_at).toLocaleString()}</td>
//                     <td>{txn.transaction_id}</td>
//                     <td>{txn.order_id}</td>
//                     <td>{parseFloat(txn.amount).toFixed(2)}</td>
//                     <td>{txn.currency}</td>
//                     <td>{txn.recipient_name || 'N/A'}</td>
//                     <td>{txn.recipient_account_number || 'N/A'}</td>
//                     <td>{txn.recipient_bank_name || 'N/A'}</td>
//                     <td>{txn.recipient_ifsc_code || 'N/A'}</td>
//                     <td>{txn.payment_type === '0' ? 'IMPS' : 'NEFT'}</td>
//                     <td>
//                       <span className={`status ${txn.status.toLowerCase()}`}>{txn.status}</span>
//                     </td>
//                     <td>
//                       <button className="action-button" onClick={() => openModal(txn)}>
//                         View
//                       </button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="12" className="no-transactions">
//                     No transactions found.
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransactions.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>

//       {/* Modal to View Transaction Details */}
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Transaction Details"
//         className="custom-modal"
//         overlayClassName="custom-modal-overlay"
//       >
//         <div className="modal-header">
//           <h2>Transaction Details</h2>
//           <button onClick={closeModal} className="modal-close-btn">
//             <FiX size={24} />
//           </button>
//         </div>
//         {selectedTransaction && (
//           <div className="modal-content">
//             <p>
//               <strong>Transaction ID:</strong> {selectedTransaction.transaction_id}
//             </p>
//             <p>
//               <strong>Order ID:</strong> {selectedTransaction.order_id}
//             </p>
//             <p>
//               <strong>Amount:</strong> {parseFloat(selectedTransaction.amount).toFixed(2)}
//             </p>
//             <p>
//               <strong>Currency:</strong> {selectedTransaction.currency}
//             </p>
//             <p>
//               <strong>Recipient Name:</strong> {selectedTransaction.recipient_name || 'N/A'}
//             </p>
//             <p>
//               <strong>Account Number:</strong> {selectedTransaction.recipient_account_number || 'N/A'}
//             </p>
//             <p>
//               <strong>Bank Name:</strong> {selectedTransaction.recipient_bank_name || 'N/A'}
//             </p>
//             <p>
//               <strong>IFSC Code:</strong> {selectedTransaction.recipient_ifsc_code || 'N/A'}
//             </p>
//             <p>
//               <strong>Payment Type:</strong> {selectedTransaction.payment_type === '0' ? 'IMPS' : 'NEFT'}
//             </p>
//             <p>
//               <strong>Status:</strong> {selectedTransaction.status}
//             </p>
//           </div>
//         )}
//       </Modal>
//     </div>
//   );
// };

// export default PayoutTransactions;





// // src/components/PayoutTransactions.js

// import React, { useState, useEffect } from 'react';
// import '../styles/PayoutTransactions.css'; // Import the new CSS
// import Modal from 'react-modal';
// import { FiDownload, FiSearch, FiX } from 'react-icons/fi';
// import { CSVLink } from 'react-csv';

// Modal.setAppElement('#root');

// const PayoutTransactions = () => {
//   const [transactions, setTransactions] = useState([]);
//   const [filteredTransactions, setFilteredTransactions] = useState([]);
//   const [activeTab, setActiveTab] = useState('All');
//   const [currentPage, setCurrentPage] = useState(1);
//   const transactionsPerPage = 10;
//   const [searchTerm, setSearchTerm] = useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [selectedTransaction, setSelectedTransaction] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   // Payout Balance
//   const [payoutBalance, setPayoutBalance] = useState(0);

//   const merchantId = localStorage.getItem('merchantId');

//   // Fetch payout transactions
//   const fetchTransactions = async () => {
//     try {
//       const res = await fetch(`https://payouts.payinfy.com/payouts/transactions/${merchantId}/`);
//       const data = await res.json();
//       if (data.status === 'Success') {
//         setTransactions(data.transactions);
//       } else {
//         console.error('Error fetching payout transactions:', data.message);
//       }
//     } catch (error) {
//       console.error('Error fetching payout transactions:', error);
//     }
//   };

//   // Fetch payout balance
//   const fetchPayoutBalance = async () => {
//     try {
//       const res = await fetch(`https://payouts.payinfy.com/payouts/payout-profile/${merchantId}/`);
//       const data = await res.json();
//       if (data.payout_balance) {
//         setPayoutBalance(parseFloat(data.payout_balance));
//       }
//     } catch (error) {
//       console.error('Error fetching payout balance:', error);
//     }
//   };

//   // Initial load + polling
//   useEffect(() => {
//     fetchTransactions();
//     fetchPayoutBalance();
//     const intervalId = setInterval(fetchTransactions, 5000);
//     return () => clearInterval(intervalId);
//   }, []);

//   // Filtering logic
//   const filterTransactions = () => {
//     let filtered = [...transactions];

//     // Filter by status tab
//     if (activeTab !== 'All') {
//       filtered = filtered.filter(txn => txn.status.toLowerCase() === activeTab.toLowerCase());
//     }

//     // Filter by search term
//     if (searchTerm) {
//       filtered = filtered.filter(txn =>
//         txn.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         txn.order_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         txn.recipient_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         txn.recipient_account_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         txn.recipient_bank_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         txn.payment_type?.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }

//     // Filter by date range
//     if (startDate && endDate) {
//       filtered = filtered.filter((txn) => {
//         const txnDate = new Date(txn.created_at);
//         return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
//       });
//     }

//     setFilteredTransactions(filtered);
//   };

//   useEffect(() => {
//     filterTransactions();
//     setCurrentPage(1);
//   }, [activeTab, searchTerm, startDate, endDate]);

//   useEffect(() => {
//     filterTransactions();
//   }, [transactions]);

//   // Pagination
//   const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
//   const indexOfLastTransaction = currentPage * transactionsPerPage;
//   const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
//   const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
//   const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

//   // Modal
//   const openModal = (txn) => {
//     setSelectedTransaction(txn);
//     setModalIsOpen(true);
//   };
//   const closeModal = () => {
//     setSelectedTransaction(null);
//     setModalIsOpen(false);
//   };

//   // CSV Export config
//   const csvHeaders = [
//     { label: 'Date & Time', key: 'created_at' },
//     { label: 'Transaction ID', key: 'transaction_id' },
//     { label: 'Order ID', key: 'order_id' },
//     { label: 'Amount', key: 'amount' },
//     { label: 'Currency', key: 'currency' },
//     { label: 'Recipient Name', key: 'recipient_name' },
//     { label: 'Account Number', key: 'recipient_account_number' },
//     { label: 'Bank Name', key: 'recipient_bank_name' },
//     { label: 'IFSC Code', key: 'recipient_ifsc_code' },
//     { label: 'Payment Type', key: 'payment_type' },
//     { label: 'Status', key: 'status' },
//   ];
//   const csvData = filteredTransactions.map((txn) => ({ ...txn }));

//   return (
//     <div className="payout-transactions-content">
//       {/* 
//          Single horizontal bar containing:
//          - Tabs ("All", "Success", "Failed")
//          - Payout Balance
//          - Search input + Date filter
//          - CSV download button
//       */}
//       <div className="payout-transactions-action-bar">
//         {/* Tabs */}
//         <button
//           className={`tab-button ${activeTab === 'All' ? 'active' : ''}`}
//           onClick={() => setActiveTab('All')}
//         >
//           All
//         </button>
//         <button
//           className={`tab-button ${activeTab === 'Success' ? 'active' : ''}`}
//           onClick={() => setActiveTab('Success')}
//         >
//           Success
//         </button>
//         <button
//           className={`tab-button ${activeTab === 'Failed' ? 'active' : ''}`}
//           onClick={() => setActiveTab('Failed')}
//         >
//           Failed
//         </button>

//         {/* Payout balance widget inline */}
//         <div className="balance-widget">
//           <div className="balance-info">
//             <span className="balance-label">Payout Balance</span>
//             <span className="balance-amount">₹{payoutBalance.toLocaleString()}</span>
//           </div>
//         </div>

//         {/* Search */}
//         <div className="search-bar">
//           <input
//             type="text"
//             placeholder="Search transactions"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <FiSearch className="search-icon" />
//         </div>

//         {/* Date filter */}
//         <div className="date-filter">
//           <input
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//           />
//           <span> to </span>
//           <input
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//           />
//         </div>

//         {/* CSV Download */}
//         <CSVLink data={csvData} headers={csvHeaders} filename="payout_transactions.csv">
//           <button className="download-button">
//             <FiDownload className="download-icon" /> Download CSV
//           </button>
//         </CSVLink>
//       </div>

//       {/* Table Container */}
//       <div className="payout-transactions-table-container">
//         <div className="payout-transactions-table-wrapper">
//           <table className="payout-transactions-table">
//             <thead>
//               <tr>
//                 <th>Date & Time</th>
//                 <th>Transaction ID</th>
//                 <th>Order ID</th>
//                 <th>Amount</th>
//                 <th>Currency</th>
//                 <th>Recipient Name</th>
//                 <th>Account Number</th>
//                 <th>Bank Name</th>
//                 <th>IFSC Code</th>
//                 <th>Payment Type</th>
//                 <th>Status</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {currentTransactions.length > 0 ? (
//                 currentTransactions.map((txn) => (
//                   <tr key={txn.transaction_id}>
//                     <td>{new Date(txn.created_at).toLocaleString()}</td>
//                     <td>{txn.transaction_id}</td>
//                     <td>{txn.order_id}</td>
//                     <td>{parseFloat(txn.amount).toFixed(2)}</td>
//                     <td>{txn.currency}</td>
//                     <td>{txn.recipient_name || 'N/A'}</td>
//                     <td>{txn.recipient_account_number || 'N/A'}</td>
//                     <td>{txn.recipient_bank_name || 'N/A'}</td>
//                     <td>{txn.recipient_ifsc_code || 'N/A'}</td>
//                     <td>{txn.payment_type === '0' ? 'IMPS' : 'NEFT'}</td>
//                     <td>
//                       <span className={`status ${txn.status.toLowerCase()}`}>
//                         {txn.status}
//                       </span>
//                     </td>
//                     <td>
//                       <button className="action-button" onClick={() => openModal(txn)}>
//                         View
//                       </button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="12" className="no-transactions">
//                     No transactions found.
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination */}
//         <div className="pagination">
//           {[...Array(totalPages || 1)].map((_, idx) => (
//             <button
//               key={idx + 1}
//               className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
//               onClick={() => handlePageChange(idx + 1)}
//               disabled={filteredTransactions.length === 0}
//             >
//               {idx + 1}
//             </button>
//           ))}
//         </div>
//       </div>

//       {/* Modal */}
//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={closeModal}
//         contentLabel="Transaction Details"
//         className="custom-modal"
//         overlayClassName="custom-modal-overlay"
//       >
//         <div className="modal-header">
//           <h2>Transaction Details</h2>
//           <button onClick={closeModal} className="modal-close-btn">
//             <FiX size={24} />
//           </button>
//         </div>
//         {selectedTransaction && (
//           <div className="modal-content">
//             <p>
//               <strong>Transaction ID:</strong> {selectedTransaction.transaction_id}
//             </p>
//             <p>
//               <strong>Order ID:</strong> {selectedTransaction.order_id}
//             </p>
//             <p>
//               <strong>Amount:</strong> {parseFloat(selectedTransaction.amount).toFixed(2)}
//             </p>
//             <p>
//               <strong>Currency:</strong> {selectedTransaction.currency}
//             </p>
//             <p>
//               <strong>Recipient Name:</strong> {selectedTransaction.recipient_name || 'N/A'}
//             </p>
//             <p>
//               <strong>Account Number:</strong> {selectedTransaction.recipient_account_number || 'N/A'}
//             </p>
//             <p>
//               <strong>Bank Name:</strong> {selectedTransaction.recipient_bank_name || 'N/A'}
//             </p>
//             <p>
//               <strong>IFSC Code:</strong> {selectedTransaction.recipient_ifsc_code || 'N/A'}
//             </p>
//             <p>
//               <strong>Payment Type:</strong> {selectedTransaction.payment_type === '0' ? 'IMPS' : 'NEFT'}
//             </p>
//             <p>
//               <strong>Status:</strong> {selectedTransaction.status}
//             </p>
//           </div>
//         )}
//       </Modal>
//     </div>
//   );
// };

// export default PayoutTransactions;


// src/components/PayoutTransactions.js

import React, { useState, useEffect } from 'react';
import '../styles/PayoutTransactions.css'; // Import the new CSS
import Modal from 'react-modal';
import { FiDownload, FiSearch, FiX } from 'react-icons/fi';
import { CSVLink } from 'react-csv';

Modal.setAppElement('#root');

const PayoutTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // NEW: payout balance
  const [payoutBalance, setPayoutBalance] = useState(0);

  const merchantId = localStorage.getItem('merchantId');

  // Fetch transactions
  const fetchTransactions = async () => {
    try {
      const res = await fetch(`https://payouts.payinfy.com/payouts/transactions/${merchantId}/`);
      const data = await res.json();
      if (data.status === 'Success') {
        setTransactions(data.transactions);
      } else {
        console.error('Error:', data.message);
      }
    } catch (err) {
      console.error('Error fetching payout transactions:', err);
    }
  };

  // Fetch payout balance
  const fetchPayoutBalance = async () => {
    try {
      const res = await fetch(`https://payouts.payinfy.com/payouts/payout-profile/${merchantId}/`);
      const data = await res.json();
      if (data.payout_balance) {
        setPayoutBalance(parseFloat(data.payout_balance));
      }
    } catch (err) {
      console.error('Error fetching payout balance:', err);
    }
  };

  // Initial load + polling
  useEffect(() => {
    fetchTransactions();
    fetchPayoutBalance();
    const intervalId = setInterval(fetchTransactions, 5000);
    return () => clearInterval(intervalId);
  }, []);

  // Filter logic
  const filterTransactions = () => {
    let filtered = [...transactions];

    // Status filter
    if (activeTab !== 'All') {
      filtered = filtered.filter(
        (txn) => txn.status.toLowerCase() === activeTab.toLowerCase()
      );
    }

    // Search term
    if (searchTerm) {
      filtered = filtered.filter((txn) =>
        txn.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        txn.order_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        txn.recipient_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        txn.recipient_account_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        txn.recipient_bank_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        txn.payment_type?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Date range
    if (startDate && endDate) {
      filtered = filtered.filter((txn) => {
        const txnDate = new Date(txn.created_at);
        return txnDate >= new Date(startDate) && txnDate <= new Date(endDate);
      });
    }

    setFilteredTransactions(filtered);
  };

  useEffect(() => {
    filterTransactions();
    setCurrentPage(1);
  }, [activeTab, searchTerm, startDate, endDate]);

  useEffect(() => {
    filterTransactions();
  }, [transactions]);

  // Pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

  // Modal
  const openModal = (txn) => {
    setSelectedTransaction(txn);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setSelectedTransaction(null);
    setModalIsOpen(false);
  };

  // CSV export
  const csvHeaders = [
    { label: 'Date & Time', key: 'created_at' },
    { label: 'Transaction ID', key: 'transaction_id' },
    { label: 'Order ID', key: 'order_id' },
    { label: 'Amount', key: 'amount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Recipient Name', key: 'recipient_name' },
    { label: 'Account Number', key: 'recipient_account_number' },
    { label: 'Bank Name', key: 'recipient_bank_name' },
    { label: 'IFSC Code', key: 'recipient_ifsc_code' },
    { label: 'Payment Type', key: 'payment_type' },
    { label: 'Status', key: 'status' },
  ];
  const csvData = filteredTransactions.map((txn) => ({ ...txn }));

  return (
    <div className="payout-transactions-content">
      {/* 
        Single row for: Tabs (All/Success/Failed), Payout Balance,
        Search bar, Date filters, CSV Download 
      */}
      <div className="payout-transactions-action-bar">
        {/* Tabs */}
        <button
          className={`tab-button ${activeTab === 'All' ? 'active' : ''}`}
          onClick={() => setActiveTab('All')}
        >
          All
        </button>
        <button
          className={`tab-button ${activeTab === 'Success' ? 'active' : ''}`}
          onClick={() => setActiveTab('Success')}
        >
          Success
        </button>
        <button
          className={`tab-button ${activeTab === 'Failed' ? 'active' : ''}`}
          onClick={() => setActiveTab('Failed')}
        >
          Failed
        </button>

        {/* Payout Balance Widget */}
        <div className="balance-widget">
          <div className="balance-info">
            <span className="balance-label">Payout Balance</span>
            <span className="balance-amount">
              ₹{payoutBalance.toLocaleString()}
            </span>
          </div>
        </div>

        {/* Search Bar */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search transactions"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FiSearch className="search-icon" />
        </div>

        {/* Date Filter */}
        <div className="date-filter">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <span> to </span>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        {/* CSV Download Button */}
        <CSVLink data={csvData} headers={csvHeaders} filename="payout_transactions.csv">
          <button className="download-button">
            <FiDownload className="download-icon" /> Download CSV
          </button>
        </CSVLink>
      </div>

      {/* Transactions Table */}
      <div className="payout-transactions-table-container">
        <div className="payout-transactions-table-wrapper">
          <table className="payout-transactions-table">
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>Transaction ID</th>
                <th>Order ID</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Recipient Name</th>
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>IFSC Code</th>
                <th>Payment Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentTransactions.length > 0 ? (
                currentTransactions.map((txn) => (
                  <tr key={txn.transaction_id}>
                    <td>{new Date(txn.created_at).toLocaleString()}</td>
                    <td>{txn.transaction_id}</td>
                    <td>{txn.order_id}</td>
                    <td>{parseFloat(txn.amount).toFixed(2)}</td>
                    <td>{txn.currency}</td>
                    <td>{txn.recipient_name || 'N/A'}</td>
                    <td>{txn.recipient_account_number || 'N/A'}</td>
                    <td>{txn.recipient_bank_name || 'N/A'}</td>
                    <td>{txn.recipient_ifsc_code || 'N/A'}</td>
                    <td>{txn.payment_type === '0' ? 'IMPS' : 'NEFT'}</td>
                    <td>
                      <span className={`status ${txn.status.toLowerCase()}`}>{txn.status}</span>
                    </td>
                    <td>
                      <button className="action-button" onClick={() => openModal(txn)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="no-transactions">
                    No transactions found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          {[...Array(totalPages || 1)].map((_, idx) => (
            <button
              key={idx + 1}
              className={`page-button ${currentPage === idx + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(idx + 1)}
              disabled={filteredTransactions.length === 0}
            >
              {idx + 1}
            </button>
          ))}
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Transaction Details"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <div className="modal-header">
          <h2>Transaction Details</h2>
          <button onClick={closeModal} className="modal-close-btn">
            <FiX size={24} />
          </button>
        </div>
        {selectedTransaction && (
          <div className="modal-content">
            <p><strong>Transaction ID:</strong> {selectedTransaction.transaction_id}</p>
            <p><strong>Order ID:</strong> {selectedTransaction.order_id}</p>
            <p><strong>Amount:</strong> {parseFloat(selectedTransaction.amount).toFixed(2)}</p>
            <p><strong>Currency:</strong> {selectedTransaction.currency}</p>
            <p><strong>Recipient Name:</strong> {selectedTransaction.recipient_name || 'N/A'}</p>
            <p><strong>Account Number:</strong> {selectedTransaction.recipient_account_number || 'N/A'}</p>
            <p><strong>Bank Name:</strong> {selectedTransaction.recipient_bank_name || 'N/A'}</p>
            <p><strong>IFSC Code:</strong> {selectedTransaction.recipient_ifsc_code || 'N/A'}</p>
            <p><strong>Payment Type:</strong> {selectedTransaction.payment_type === '0' ? 'IMPS' : 'NEFT'}</p>
            <p><strong>Status:</strong> {selectedTransaction.status}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PayoutTransactions;
